import { CHANGE_THEME } from './actions';

type themeReducerType = {
  type: string;
  payload: string;
};

export const themeReducer = (previousState = 'light', { type, payload }: themeReducerType) => {
  if (type === CHANGE_THEME) {
    return payload;
  }
  return previousState;
};
