import React, { FC } from 'react';
import { TextInput, NumberInput, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';
import { isNil } from 'lodash';
import { ProjectStatus, ProjectEntity } from 'models';
import { validateUrl, validateMaxPathLength } from 'services/validators';
import { dateTimeFormat, dateTimeParse } from 'utils';
import { CrawlerTabToolbar } from './crawler-tab-toolbar';
import { CrawlingStats } from './crawling-stats';
import { PreScanForm } from './pre-scan-form';

type CrawlerTabProps = ReactAdminTabPanelItemProps & {
  project: ProjectEntity;
  statusDisabled: boolean;
};

export const CrawlerTab: FC<CrawlerTabProps> = ({ project, statusDisabled, ...restProps }) => {
  const translate = useTranslate();

  const isProjectCrawling = !isNil(project) && project.status === ProjectStatus.CRAWLING;

  return (
    <>
      <Grid container={true}>
        {project.crawlingStartDate && (
          <Grid item={true} xs={12}>
            <TextInput
              source="crawlingStartDate"
              format={dateTimeFormat}
              parse={dateTimeParse}
              disabled={true}
            />
          </Grid>
        )}
        {project.crawlingFinishedDate && (
          <Grid item={true} xs={12}>
            <TextInput
              source="crawlingFinishedDate"
              format={dateTimeFormat}
              parse={dateTimeParse}
              disabled={true}
              label={translate('gTest.project.form.finishDateLabel')}
            />
          </Grid>
        )}
        <Grid item={true} xs={12}>
          <TextInput
            source={'crawler.url'}
            label={translate('gTest.project.form.urlLabel')}
            validate={validateUrl(translate('gTest.project.form.validateUrlErrorMessage'))}
            disabled={isProjectCrawling}
            fullWidth={true}
          />
        </Grid>
        <Grid item={true} xs={2}>
          <NumberInput
            source={'crawler.maxPathLength'}
            label={translate('gTest.project.form.numberLabel')}
            validate={validateMaxPathLength}
            disabled={isProjectCrawling}
            fullWidth={true}
          />
        </Grid>
      </Grid>
      {project.status !== ProjectStatus.DRAFT && (
        <PreScanForm statusDisabled={statusDisabled} {...restProps} />
      )}
      <CrawlingStats project={project} />
      <CrawlerTabToolbar
        projectStatus={project.status}
        isProjectCrawling={isProjectCrawling}
        statusDisabled={statusDisabled}
      />
    </>
  );
};
