import React, { FC, useState } from 'react';
import { useLogin, useNotify, useTranslate, Notification } from 'react-admin';
import { CognitoUser } from 'amazon-cognito-identity-js';
import classnames from 'classnames';
import { Card, Avatar } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { changePassword, login } from 'providers/auth/auth-provider';
import { LoginStep, AuthLoginParams, ChangePasswordParams } from './login.models';
import { sanitizeRestProps, isNewPasswordRequired } from './login.utils';
import { DefaultLoginForm } from './login-form';
import { ChangePasswordForm } from './change-password';
import { useStyles } from './login.styles';

export const Login: FC<any> = props => {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | any | null>(null);
  const [requiredAttributes, setRequiredAttributes] = useState<string[]>([]);
  const [step, setStep] = useState<LoginStep>(LoginStep.LOGIN);
  const userLogin = useLogin();
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();

  const handleLogin = (auth: AuthLoginParams) => {
    return login(auth)
      .then((user: CognitoUser | any) => {
        if (isNewPasswordRequired(user)) {
          const { requiredAttributes: reqAttr } = user.challengeParam;
          setCognitoUser(user);
          setRequiredAttributes(reqAttr);
          setStep(LoginStep.CHANGE_PASSWORD);
        } else {
          userLogin(auth);
        }
      })
      .catch(() => notify(translate('ra.auth.sign_in_error')));
  };

  const handleChangePassword = (auth: ChangePasswordParams) => {
    const reqAttrData = auth.email
      ? {
          email: auth.email,
        }
      : {};

    return changePassword(cognitoUser, auth.password, reqAttrData).then(
      (user: CognitoUser | any) => {
        userLogin(user);
      },
    );
  };

  const { className, ...restProps } = props;

  return (
    <div className={classnames(classes.main, className)} {...sanitizeRestProps(restProps)}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        {step === LoginStep.LOGIN ? (
          <DefaultLoginForm login={handleLogin} />
        ) : step === LoginStep.CHANGE_PASSWORD ? (
          <ChangePasswordForm
            requiredAttributes={requiredAttributes}
            changePassword={handleChangePassword}
          />
        ) : null}
      </Card>
      <Notification />
    </div>
  );
};
