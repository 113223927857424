import * as React from 'react';
import { ProjectStatus } from 'models';
import { ProjectStatusChip } from 'components/common/project-status-chip';
import Typography from '@material-ui/core/Typography';
import { ContentListType } from './help.models';
import { mapEnumToArray } from './help.utils';

export function getContentList(): ContentListType[] {
  const list = mapEnumToArray(ProjectStatus);
  return [
    {
      title: 'Workflow',
      content: (
        <Typography>
          To create a new project in the GTest application, click the "Projects" tab in the left
          side menu. Then click button create in the upper right corner of the page window. You will
          be redirected to the project creation tab. Fill in the fields "Name", "Start URL" and mark
          the expected maximum number of steps to reproduce test cases and click "SAVE". Gtest will
          start its work. A new record with project data and its status will appear in the List of
          projects section.
        </Typography>
      ),
      video: 'https://youtu.be/FHXGHRU4UkA',
    },
    {
      title: 'Search and sort',
      content: (
        <>
          <Typography>
            The easiest way to find an entity is to use the search engine located at the top. Just
            click on the search area and enter the search phrase, the results will be displayed
            immediately.
          </Typography>
          <Typography>
            Each column can be used to sort the content, that is:: Author, Paths, Cases and Creation
            Date.
          </Typography>
        </>
      ),
      video: 'https://youtu.be/o3bzSLQUEe0',
    },
    {
      title: 'Edit and export',
      content: (
        <Typography>
          Project can be downloaded in CSV format. In the “list of projects” section click on the
          “Edit” button in order to open the record. Both “paths” and “cases” can be exported.
        </Typography>
      ),
      video: 'https://youtu.be/jED8YfiQwLE',
    },
    {
      title: 'Feedback',
      content: (
        <Typography>
          Giving feedback is not necessary, but it will greatly help us to improve our tool, so it
          will be more usefull for you in the future
        </Typography>
      ),
    },
    {
      title: 'Project statuses',
      content: (
        <React.Fragment>
          <Typography>
            Project go through 4 (optionally 6) stages. In each stage you can do different things.
            Please find below description of each status and what is possible in each status
          </Typography>
          {list.map(status => (
            <div key={status}>
              <ProjectStatusChip status={status} tooltip={false} description={true} />
            </div>
          ))}
        </React.Fragment>
      ),
    },
  ];
}
