import React, { FC, ReactElement, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { cond, matches, stubTrue } from 'lodash';
import { Placeholder } from 'components/common/placeholder';
import { ProjectStatus, ProjectEntity } from 'models';
import { PathsList } from './paths-list';

type PathsTabProps = ReactAdminTabPanelItemProps & {
  project: ProjectEntity;
};

export const PathsTab: FC<PathsTabProps> = ({ project, ...props }) => {
  const translate = useTranslate();

  const dataToDisplay = useCallback(
    (projectStatus?: ProjectStatus) => {
      const renderPlaceholder = (message: string) => {
        return <Placeholder message={message} />;
      };

      const renderPathList = () => {
        return <PathsList {...props} />;
      };

      return cond<ProjectStatus | undefined, ReactElement>([
        [
          matches(ProjectStatus.DRAFT),
          () => renderPlaceholder(translate('gTest.project.pathsTab.newDraftStatus')),
        ],
        [
          matches(ProjectStatus.PRE_SCANNING),
          () => renderPlaceholder(translate('gTest.project.pathsTab.preScanStatus')),
        ],
        [
          matches(ProjectStatus.PENDING),
          () => renderPlaceholder(translate('gTest.project.pathsTab.pendingStatus')),
        ],
        [
          matches(ProjectStatus.CRAWLING),
          () => renderPlaceholder(translate('gTest.project.pathsTab.crawlingStatus')),
        ],
        [matches(ProjectStatus.REVIEW), () => renderPathList()],
        [matches(ProjectStatus.COMPLETED), () => renderPathList()],
        [stubTrue, () => renderPlaceholder(translate('gTest.project.pathsTab.defaultStatus'))],
      ])(projectStatus);
    },
    [translate, props],
  );

  return dataToDisplay(project ? project.status : undefined);
};
