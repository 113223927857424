import React, { FC, useMemo, useCallback } from 'react';
import { useTranslate, useGetOne, Loading } from 'react-admin';
import { Modal, Button } from '@material-ui/core';
import { constProvider } from 'providers/const';
import { useStyles } from './screenshot-img.styles';

type ScreenshotImgProps = {
  id: string;
};

export const ScreenshotImg: FC<ScreenshotImgProps> = ({ id }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);

  const response = useGetOne(constProvider.RESOURCES.PROJECT_PATH_IMG, id);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const imgInRightFormat = useMemo(
    () => response.data && response.data.image && `data:image/png;base64, ${response.data.image}`,
    [response],
  );

  if (response.loading) {
    return <Loading />;
  }

  if (response.error) {
    return <p className={classes.error}>{translate('common.rowExpanded.screenshotImg.error')}</p>;
  }

  return (
    <div>
      {imgInRightFormat ? (
        <div>
          <Button onClick={handleOpen} className={classes.button}>
            <img
              className={classes.buttonImg}
              src={imgInRightFormat}
              alt={translate('common.rowExpanded.screenshotImg.alt')}
            />
          </Button>
          <Modal open={open} onClose={handleClose}>
            <img
              className={classes.modalImg}
              src={imgInRightFormat}
              alt={translate('common.rowExpanded.screenshotImg.alt')}
            />
          </Modal>
        </div>
      ) : (
        <p className={classes.error}>{translate('common.rowExpanded.screenshotImg.error')}</p>
      )}
    </div>
  );
};
