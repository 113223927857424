import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  main: {
    flex: '1',
    marginTop: 40,
  },
  titleLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  card: {
    padding: '16px 0',
    overflow: 'inherit',
    textAlign: 'left',
  },
  title: {
    padding: '0 16px',
  },
  value: {
    padding: '16px',
    minHeight: 30,
  },
  valueWithVideo: {
    display: 'flex',
    padding: '16px',
    minHeight: 30,
  },
  content: {
    width: '45%',
    marginRight: '40px',
  },
  avatar: {
    background: theme.palette.background.paper,
  },
  listItemText: {
    overflowY: 'hidden',
    height: '4em',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
}));
