import React, { FC, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate, useDataProvider } from 'react-admin';
import { includes } from 'lodash';
import * as Types from 'Types';
import { ProjectEntity, ProjectStatus } from 'models';
import { getPathName } from 'utils';
import { Placeholder } from 'components/common/placeholder';
import { CrawlingStatsResponse } from './crawling-stats.model';
import { Stats } from './stats';

type CrawlingStatsProps = ReactAdminTabPanelItemProps & {
  project: ProjectEntity;
};

export const CrawlingStats: FC<CrawlingStatsProps> = ({ project, ...props }) => {
  const translate = useTranslate();
  const router = useSelector((state: Types.RootState) => state.router);
  const dataProvider = useDataProvider();
  const [isStatsLoading, setIsStatsLoading] = useState<boolean>(true);
  const [statsError, setStatsError] = useState<Error | undefined>();
  const [stats, setStats] = useState<CrawlingStatsResponse | undefined>();

  const handleGetCrawlingStats = useCallback(() => {
    dataProvider
      .getOne(`${getPathName(router.location)}`, { id: 'stats' })
      .then(({ data }: { data: CrawlingStatsResponse }) => {
        setIsStatsLoading(false);
        setStats(data);
      })
      .catch((e: Error) => {
        setIsStatsLoading(false);
        setStatsError(e);
      });
  }, [dataProvider, router.location]);

  useEffect(() => {
    handleGetCrawlingStats();
  }, [dataProvider, router.location, handleGetCrawlingStats]);

  const renderNotAvailableInfo = useCallback(
    () => (
      <Placeholder message={translate('gTest.project.crawlerTab.crawlingStats.notAvailable')} />
    ),
    [translate],
  );

  const renderTab = useCallback(
    () => <Stats loading={isStatsLoading} data={stats} error={statsError} />,
    [isStatsLoading, stats, statsError],
  );

  return includes(
    [ProjectStatus.REVIEW, ProjectStatus.COMPLETED, ProjectStatus.CRAWLING],
    project.status,
  )
    ? renderTab()
    : renderNotAvailableInfo();
};
