import React, { FC, useMemo } from 'react';
import { Edit, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import * as Types from 'Types';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getProjectFromReactAdminSelector } from 'features/admin/selectors';
import { ProjectStatus } from 'models';
import { PostTitle } from './post-title';
import { ProjectEditForm } from './project-edit-form';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  edit: {
    '& > *': {
      marginTop: 0,
    },
  },
  disabledMessage: {
    textAlign: 'center',
    color: 'red',
    fontSize: '20px',
    margin: '10px 0',
  },
}));

type ProjectEditProps = ReactAdminCreateEditProps & {};

export const ProjectEdit: FC<ProjectEditProps> = props => {
  const project = useSelector((state: Types.RootState) =>
    getProjectFromReactAdminSelector(state, props.id),
  );
  const classes = useStyles();
  const translate = useTranslate();

  const statusDisabled = useMemo(() => project && project.status === ProjectStatus.COMPLETED, [
    project,
  ]);

  const statusCompletedMessage = () => {
    let message = null;
    if (statusDisabled) {
      message = (
        <Typography variant="h5" component="h2" className={classes.disabledMessage}>
          {translate('gTest.project.projectEdit.messageCompleted')}
        </Typography>
      );
    }
    return message;
  };

  return (
    <div className={classes.root}>
      {statusCompletedMessage()}
      <Edit {...props} className={classes.edit} title={<PostTitle />}>
        <ProjectEditForm project={project} statusDisabled={statusDisabled} />
      </Edit>
    </div>
  );
};
