import { elementTypes, defaultSelected } from './filters';

export const constProvider = Object.freeze({
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL as string,
  RESOURCES: {
    PROJECT: 'v1/project',
    PROJECT_PATHS: 'paths',
    PROJECT_PATH_IMG: 'image',
    PROJECT_MARK_PATHS: 'mark-path',
    PROJECT_MARK_ALL_PATHS: 'mark-all-as-cases',
    PROJECT_CASES: 'cases',
    PROJECT_FEEDBACK: 'feedback',
    PROJECT_FEEDBACK_STATS: 'feedback-stats',
    PROJECT_FEEDBACK_LIST: 'feedback-list',
    PROJECT_START_PRE_SCAN: 'start-pre-scan',
    PROJECT_START_CRAWLER: 'start-crawler',
    PROJECT_STOP_CRAWLER: 'stop-crawler',
    PROJECT_STATS: 'stats',
    PROJECT_PRE_SCAN_FORMS: 'pre-scan-forms',
    PROJECT_PRE_SCAN_SAMPLE: 'pre-scan-sample',
  },
  AUTH: {
    AWS_COGNITO_REGION: process.env.REACT_APP_AWS_COGNITO_REGION as string,
    AWS_COGNITO_USER_POOL_ID: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID as string,
    AWS_COGNITO_WEB_CLIENT_ID: process.env.REACT_APP_AWS_COGNITO_WEB_CLIENT_ID as string,
    AWS_COGNITO_AUTHENTICATION_FLOW_TYPE: process.env
      .REACT_APP_AWS_COGNITO_AUTHENTICATION_FLOW_TYPE as string,
    AWS_COGNITO_OAUTH_DOMAIN: process.env.REACT_APP_AWS_COGNITO_OAUTH_DOMAIN as string,
    AWS_COGNITO_OAUTH_SCOPE: process.env.REACT_APP_AWS_COGNITO_OAUTH_SCOPE as string,
    AWS_COGNITO_OAUTH_REDIRECT_SIGN_IN: process.env
      .REACT_APP_AWS_COGNITO_OAUTH_REDIRECT_SIGN_IN as string,
    AWS_COGNITO_OAUTH_REDIRECT_SIGN_OUT: process.env
      .REACT_APP_AWS_COGNITO_OAUTH_REDIRECT_SIGN_OUT as string,
    AWS_COGNITO_OAUTH_RESPONSE_TYPE: process.env
      .REACT_APP_AWS_COGNITO_OAUTH_RESPONSE_TYPE as string,
  },
  ELEMENT_TYPES: elementTypes,
  ELEMENT_TYPES_DEFAULT_SELECTED: defaultSelected,
  DATE_TIME_FORMAT: 'DD-MM-YYYY HH:mm:ss',
});
