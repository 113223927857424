export enum LoginStep {
  LOGIN = 'login',
  CHANGE_PASSWORD = 'change-password',
}

export type AuthLoginParams = {
  username: string;
  password: string;
};

export type ChangePasswordParams = {
  email?: string;
  password: string;
};
