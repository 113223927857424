import React, { FC } from 'react';
import { useTranslate, TextInput } from 'react-admin';
import { Form, FormRenderProps } from 'react-final-form';
import { CardActions, Button, Typography } from '@material-ui/core';
import { requiredValidator, emailValidator } from 'services/validators';
import { ChangePasswordParams } from '../login.models';
import { useStyles } from '../login-form.styles';

type ChangeLoginFormProps = {
  changePassword: (auth: ChangePasswordParams) => void;
  requiredAttributes: string[];
};

export const ChangePasswordForm: FC<ChangeLoginFormProps> = ({
  changePassword,
  requiredAttributes,
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  const submit = (values: any) => {
    changePassword({ email: values.email, password: values.password });
  };

  const renderForm = ({ handleSubmit }: FormRenderProps<any>) => {
    return (
      <form onSubmit={handleSubmit}>
        <div className={classes.form}>
          <Typography variant="caption">{translate('app.login.changePassword.info')}</Typography>
          {requiredAttributes.includes('email') && (
            <div>
              <TextInput
                source="email"
                label={translate('app.login.changePassword.emailLabel')}
                validate={emailValidator}
                fullWidth={true}
              />
            </div>
          )}
          <div>
            <TextInput
              source="password"
              label={translate('ra.auth.password')}
              type="password"
              validate={requiredValidator}
              fullWidth={true}
            />
          </div>
        </div>
        <CardActions>
          <Button variant="contained" type="submit" color="primary" className={classes.button}>
            {translate('ra.auth.sign_in')}
          </Button>
        </CardActions>
      </form>
    );
  };

  return <Form onSubmit={submit} render={renderForm} />;
};
