import { historyProvider } from './../history/index';
import simpleRestProvider from 'ra-data-simple-rest';
import { constProvider } from '../const';
import { getEntityIdFromUrl } from 'utils';
import { httpProvider } from '../http';
import { getProjectGetList } from './project';

const baseDataProvider = simpleRestProvider(constProvider.BASE_API_URL, httpProvider);

const projectResource = constProvider.RESOURCES.PROJECT;

export const dataProvider = {
  ...baseDataProvider,

  update: (resource: string, params: any) => {
    const projectCaseFeedback = constProvider.RESOURCES.PROJECT_FEEDBACK;
    const projectScenario = constProvider.RESOURCES.PROJECT_PRE_SCAN_SAMPLE;

    if (resource === projectCaseFeedback) {
      return baseDataProvider.update(
        `${projectResource}/${getEntityIdFromUrl(historyProvider.location)}/${projectCaseFeedback}`,
        params,
      );
    }

    if (resource === projectScenario) {
      return baseDataProvider.update(
        `${projectResource}/${getEntityIdFromUrl(historyProvider.location)}/${projectScenario}`,
        params,
      );
    }

    return baseDataProvider.update(resource, params);
  },

  delete: (resource: string, params: any) => {
    const projectScenario = constProvider.RESOURCES.PROJECT_PRE_SCAN_SAMPLE;

    if (resource === projectScenario) {
      return baseDataProvider.delete(
        `${projectResource}/${getEntityIdFromUrl(historyProvider.location)}/${projectScenario}`,
        params,
      );
    }

    return baseDataProvider.delete(resource, params);
  },

  create: (resource: string, params: any) => {
    const projectMarkPathsResource = constProvider.RESOURCES.PROJECT_MARK_PATHS;
    const projectMarkAllPathsResource = constProvider.RESOURCES.PROJECT_MARK_ALL_PATHS;

    if (resource === projectMarkPathsResource) {
      return baseDataProvider.create(
        `${projectResource}/${getEntityIdFromUrl(
          historyProvider.location,
        )}/${projectMarkPathsResource}`,
        params,
      );
    }

    if (resource === projectMarkAllPathsResource) {
      return baseDataProvider.create(
        `${projectResource}/${getEntityIdFromUrl(
          historyProvider.location,
        )}/${projectMarkAllPathsResource}`,
        params,
      );
    }

    return baseDataProvider.create(resource, params);
  },

  getList: (resource: string, params: any) => {
    const projectGetList = getProjectGetList(resource, params, baseDataProvider);
    return projectGetList || baseDataProvider.getList(resource, params);
  },

  getOne: (resource: string, params: any) => {
    const projectGetImage = constProvider.RESOURCES.PROJECT_PATH_IMG;

    if (resource === projectGetImage) {
      return baseDataProvider.getOne(
        `${projectResource}/${getEntityIdFromUrl(historyProvider.location)}/${projectGetImage}`,
        params,
      );
    }

    return baseDataProvider.getOne(resource, params);
  },
};
