import React, { FC } from 'react';
import { Card, Typography, Divider } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import { useStyles } from './info-card.styles';
import { CardIcon } from '../card-icon';
import { Video } from './video';

type InfoCardProps = {
  info: {
    title: string;
    content: string | React.ReactElement<{}>;
    video?: any;
  };
};

export const InfoCard: FC<InfoCardProps> = ({ info }) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <CardIcon Icon={CommentIcon} bgColor="#2196f3" />
      <Card className={classes.card}>
        <Typography className={classes.value} color="textSecondary">
          {info.title}
        </Typography>
        <Divider />
        {info.video ? (
          <div className={classes.valueWithVideo}>
            <div className={classes.content}>{info.content}</div>
            <Video className={classes.content} video={info.video} />
          </div>
        ) : (
          <div className={classes.value}>{info.content}</div>
        )}
      </Card>
    </div>
  );
};
