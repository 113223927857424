import React, { FC } from 'react';
import { Admin, Resource } from 'react-admin';
import { themeReducer } from './configuration';
import {
  authProvider,
  translationProvider,
  dataProvider,
  constProvider,
  historyProvider,
} from 'providers';
import { ProjectCreate, ProjectsList, ProjectEdit } from 'components';
import { customRoutes } from 'router/customRoutes';
import { Login } from './login';
import { CustomLayout } from './layout';

export const App: FC = () => {
  return (
    <Admin
      dataProvider={dataProvider}
      i18nProvider={translationProvider}
      authProvider={authProvider}
      history={historyProvider}
      loginPage={Login}
      layout={CustomLayout}
      customRoutes={customRoutes}
      customReducers={{ theme: themeReducer }}
    >
      <Resource
        name={constProvider.RESOURCES.PROJECT}
        list={ProjectsList}
        edit={ProjectEdit}
        create={ProjectCreate}
        options={{ label: 'Projects' }}
      />
      <Resource name={constProvider.RESOURCES.PROJECT_PATHS} />
      <Resource name={constProvider.RESOURCES.PROJECT_CASES} />
      <Resource name={constProvider.RESOURCES.PROJECT_FEEDBACK} />
      <Resource name={constProvider.RESOURCES.PROJECT_FEEDBACK_STATS} />
      <Resource name={constProvider.RESOURCES.PROJECT_FEEDBACK_LIST} />
      <Resource name={constProvider.RESOURCES.PROJECT_PATH_IMG} />
      <Resource name={constProvider.RESOURCES.PROJECT_STATS} />
      <Resource name={constProvider.RESOURCES.PROJECT_PRE_SCAN_FORMS} />
    </Admin>
  );
};
