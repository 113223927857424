import React, { FC } from 'react';
import ReactPlayer from 'react-player';

type VideoProps = {
  video: string;
  className?: string;
};

export const Video: FC<VideoProps> = ({ video, className }) => {
  return <ReactPlayer url={video} className={className} />;
};
