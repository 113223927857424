import React, { FC, useState, useRef, useCallback } from 'react';
import { useTranslate, useRefresh } from 'react-admin';
import { useSelector } from 'react-redux';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import * as Types from 'Types';
import { CrawlingProgress, PhaseType } from 'models';
import { getPathName } from 'utils';
import { useDestroySubscriptionsRefEffect } from 'hooks/use-destroy-subscriptions-ref-effect';
import { getCrawlingProgress } from 'services/crawler';

type CrawlerToolbarLoaderProps = ReactAdminToolbarChildrenProps & {};

const useStyles = makeStyles({
  loader: {
    marginLeft: '100px',
    display: 'flex',
    alignItems: 'center',
  },
  errorText: {
    color: 'red',
  },
  loaderText: {
    marginRight: '20px',
    marginBottom: 0,
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
  },
  progress: {
    width: '150px',
    marginBottom: '5px',
  },
});

const phasesNames = {
  FINALIZATION: 'Finalization',
  PATHS_GENERATION: 'Paths generation',
  DISCOVERY: 'Discovery',
  INITIALIZATION: 'Initialization',
  FINISHED: 'Finished',
  STARTING_CRAWLER: 'Starting crawler',
  SCORING: 'Scoring',
};

export const CrawlerToolbarLoader: FC<CrawlerToolbarLoaderProps> = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const refresh = useRefresh();
  const destroySubscriptionsRef = useRef<Subject<any>>(new Subject());
  const router = useSelector((state: Types.RootState) => state.router);
  const [error, setError] = useState<Error | undefined>();
  const [progress, setProgress] = useState<CrawlingProgress | undefined>();
  const phaseName = progress && phasesNames[progress.phase];

  const handleGetCrawlingProgress = useCallback(
    () =>
      getCrawlingProgress(`${getPathName(router.location)}`).pipe(
        tap(
          ({ data }: { data: CrawlingProgress }) => {
            setProgress(data);

            if (data.phase === PhaseType.FINISHED) {
              refresh();
            }
          },
          (e: Error) => setError(e),
        ),
      ),
    [router.location, refresh],
  );

  useDestroySubscriptionsRefEffect(destroySubscriptionsRef, handleGetCrawlingProgress);

  return (
    <div className={classes.loader}>
      {error && <p className={classes.errorText}>{translate('ra.page.error')}</p>}
      {progress && (
        <>
          <p className={classes.loaderText}>{translate('gTest.project.crawlerTab.loaderText')}</p>
          <div className={classes.progressContainer}>
            {phaseName} ({progress.x}/{progress.y})
            <LinearProgress
              className={classes.progress}
              variant="determinate"
              value={(progress.x / progress.y) * 100}
            />
          </div>
        </>
      )}
    </div>
  );
};
