import React, { FC } from 'react';
import { Datagrid, TextField, List, FunctionField, useTranslate, useRefresh } from 'react-admin';
import { constProvider } from 'providers/const';
import { FeedbackField } from 'components/common/feedback-field';
import { FeedbackListItem } from './feedback-list.models';
import { renderSubjectField } from './feedback-list.utils';

type FeedbackListProps = ReactAdminListProps & {
  statusDisabled: boolean;
  onActionSuccessful: () => void;
};

export const FeedbackList: FC<FeedbackListProps> = ({
  statusDisabled,
  onActionSuccessful,
  ...props
}) => {
  const translate = useTranslate();
  const refresh = useRefresh();

  const handleActionSuccessful = () => {
    refresh();
    onActionSuccessful();
  };

  const renderFeedbackField = (record: FeedbackListItem) => (
    <FeedbackField
      feedback={record}
      disabled={statusDisabled}
      onActionSuccessful={handleActionSuccessful}
    />
  );

  return (
    <List
      {...props}
      resource={constProvider.RESOURCES.PROJECT_FEEDBACK_LIST}
      hasList={false}
      hasEdit={false}
      hasShow={false}
      hasCreate={false}
      bulkActionButtons={false}
      title={translate('gTest.project.feedbackTab.feedbackList.title')}
    >
      <Datagrid>
        <FunctionField
          label={translate('gTest.project.feedbackTab.feedbackList.subject')}
          render={renderSubjectField}
        />
        <FunctionField
          label={translate('gTest.project.pathsTab.pathsList.feedback')}
          render={renderFeedbackField}
        />
        <TextField
          source="comment"
          label={translate('gTest.project.feedbackTab.feedbackList.comment')}
        />
      </Datagrid>
    </List>
  );
};
