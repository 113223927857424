export type CasesButtonProps = {
  selectedIds: string[];
  type: CasesButtonType;
  selectionResource: string;
};

export enum CasesButtonType {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export enum MarkType {
  PATH = 'PATH',
  CASE = 'CASE',
}
