import React, { FC, useMemo, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { Tooltip, Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ProjectStatus } from 'models';
import { find, values, matches, constant, stubTrue, cond } from 'lodash';

const useStyles = makeStyles({
  static: {
    color: '#2196f3',
    borderColor: '#2196f3',
  },
  preScan: {
    color: '#ff9800',
    borderColor: '#ff9800',
  },
  inProgress: {
    color: 'rgb(220, 0, 78)',
    borderColor: 'rgb(220, 0, 78)',
  },
  closed: {
    color: 'rgba(0, 0, 0, 0.87)',
    borderColor: 'rgba(0, 0, 0, 0.87)',
  },
  tooltip: {
    fontSize: '15px',
  },
  description: {
    margin: '15px 20px',
    display: 'inline-block',
  },
  chipContainer: {
    minWidth: '130px',
    display: 'inline-block',
    textAlign: 'right',
  },
});

type ProjectStatusChipProps = {
  status: string;
  tooltip?: boolean;
  description?: boolean;
};

const tooltipInfo = [
  { DRAFT: 'gTest.projectsList.projectStatusWithTooltip.DRAFT' },
  { PRE_SCANNING: 'gTest.projectsList.projectStatusWithTooltip.PRE_SCANNING' },
  { PENDING: 'gTest.projectsList.projectStatusWithTooltip.PENDING' },
  { CRAWLING: 'gTest.projectsList.projectStatusWithTooltip.CRAWLING' },
  { REVIEW: 'gTest.projectsList.projectStatusWithTooltip.REVIEW' },
  { COMPLETED: 'gTest.projectsList.projectStatusWithTooltip.COMPLETED' },
];

export const ProjectStatusChip: FC<ProjectStatusChipProps> = ({
  status,
  tooltip = true,
  description = false,
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const staticClasses = constant(classes.static);
  const preScanClasses = constant(classes.preScan);
  const inProgressClasses = constant(classes.inProgress);
  const closedClasses = constant(classes.closed);

  const style = useMemo(
    () =>
      cond([
        [matches(ProjectStatus.DRAFT), staticClasses],
        [matches(ProjectStatus.REVIEW), staticClasses],
        [matches(ProjectStatus.PRE_SCANNING), preScanClasses],
        [matches(ProjectStatus.PENDING), staticClasses],
        [matches(ProjectStatus.CRAWLING), inProgressClasses],
        [matches(ProjectStatus.COMPLETED), closedClasses],
        [stubTrue, staticClasses],
      ])(status),
    [status, staticClasses, preScanClasses, inProgressClasses, closedClasses],
  );

  const getDescription = useCallback(
    (projectStatus: string) => {
      const message = values(find(tooltipInfo, projectStatus));
      const messageToDisplay = translate(message);
      return messageToDisplay;
    },
    [translate],
  );

  return (
    <React.Fragment>
      {tooltip ? (
        <Tooltip title={getDescription(status)} classes={{ tooltip: classes.tooltip }}>
          <Chip variant="outlined" className={style} label={status} />
        </Tooltip>
      ) : (
        <span className={classes.chipContainer}>
          <Chip variant="outlined" className={style} label={status} />
        </span>
      )}
      {description && (
        <Typography className={classes.description}>{getDescription(status)}</Typography>
      )}
    </React.Fragment>
  );
};
