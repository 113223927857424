import React from 'react';
import { Route } from 'react-router-dom';
import { Help } from 'components/App/help';
import { Configuration } from 'components/App/configuration';

export enum routerPaths {
  HELP = '/help',
  CONFIGURATION = '/configuration',
}

export const customRoutes = [
  <Route exact={true} key="help" path={routerPaths.HELP} component={Help} />,
  <Route
    exact={true}
    key="configuration"
    path={routerPaths.CONFIGURATION}
    component={Configuration}
  />,
];
