import React, { FC, useMemo, useCallback } from 'react';
import {
  useTranslate,
  TextInput,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';
import { SamplesToolbar } from './samples-toolbar';
import { useStyles } from './row-expanded.styles';

type RowExpandedProps = {
  record?: any;
  statusDisabled: boolean;
};

type FormDataConsumerType = {
  getSource: any;
  scopedFormData: any;
  formData: any;
};

const transformValue = (v: string) => (v ? v : '');

export const RowExpanded: FC<RowExpandedProps> = ({ statusDisabled, record }, props) => {
  const recordSeps = get(record, 'steps');
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const initialValues = useMemo(() => ({ steps: recordSeps }), [recordSeps]);

  const handleSaveScenario = useCallback(
    (values: any) => {
      const arrayOfSteps = values.steps.map((element: any) => ({
        id: element.id,
        sample: element.sample,
      }));
      dataProvider
        .update('pre-scan-sample', {
          id: record.id,
          data: arrayOfSteps,
        })
        .then(({ data }: { data: any }) => {
          refresh();
          notify('gTest.project.crawlerTab.preScanForm.rowExpanded.parameterUpdated');
        })
        .catch((error: Error) => {
          notify(
            'gTest.project.crawlerTab.preScanForm.rowExpanded.parameterUpdateError',
            'warning',
            { errorMessage: error.message },
          );
        });
    },
    [refresh, dataProvider, notify, record],
  );

  return (
    <SimpleForm
      initialValues={initialValues}
      toolbar={<SamplesToolbar onSave={handleSaveScenario} disabled={statusDisabled} />}
    >
      <ArrayInput
        source="steps"
        label={translate('gTest.project.crawlerTab.preScanForm.rowExpanded.arrayInputLabel')}
        className={classes.arrayInput}
      >
        <SimpleFormIterator disableRemove={true} disableAdd={true}>
          <FormDataConsumer>
            {({ getSource, scopedFormData }: FormDataConsumerType) => {
              return (
                <>
                  <div className={classes.row}>
                    <Typography color="textSecondary" className={classes.title}>
                      {translate('gTest.project.crawlerTab.preScanForm.rowExpanded.lookup')}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {scopedFormData.lookup.map((word: string) => (
                        <span key={word}>{word}</span>
                      ))}
                    </Typography>
                  </div>
                  <div className={classes.row}>
                    <Typography color="textSecondary" className={classes.title}>
                      {translate('gTest.project.crawlerTab.preScanForm.rowExpanded.sample')}
                    </Typography>
                    <div className={classes.inputs}>
                      {scopedFormData.sample &&
                        Object.entries(scopedFormData.sample).map(([key, value]) => {
                          const source = getSource(`sample.${key}`);
                          return (
                            <TextInput
                              key={source}
                              source={source}
                              label={key}
                              className={classes.input}
                              disabled={statusDisabled}
                              format={transformValue}
                              parse={transformValue}
                            />
                          );
                        })}
                    </div>
                  </div>
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};
