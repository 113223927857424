import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    color: '#2196f3',
  },
});

type PlaceholderProps = {
  message: string;
};

export const Placeholder: FC<PlaceholderProps> = ({ message }) => {
  const classes = useStyles();

  return (
    <Typography variant="subtitle1" className={classes.root}>
      {message}
    </Typography>
  );
};
