import React, { FC, PropsWithChildren } from 'react';
import { Typography, Box } from '@material-ui/core';

type TabPanelProps = PropsWithChildren<{
  index: number;
  value: number;
  className: string;
}>;

export const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};
