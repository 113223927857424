export const elementTypes = [
  {
    value: 'BUTTON',
    name: 'Button',
  },
  {
    value: 'FORM',
    name: 'Form',
  },
  {
    value: 'INPUT',
    name: 'Input',
  },
  {
    value: 'LINK',
    name: 'Link',
  },
];

export const defaultSelected = elementTypes.map(option => option.value);
