import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/core/styles';
import backgroundImage from './backgroundImage.jpg';

const useStyles = makeStyles({
  media: {
    height: '16em',
  },
});

export const Welcome: FC = () => {
  const classes = useStyles();
  return (
    <Card>
      <CardMedia image={backgroundImage} className={classes.media} />
      <CardContent>
        <Typography variant="h5" component="h2">
          Welcome to GTest testing tool
        </Typography>
        <Typography component="p">
          Gtest is a tool created by to optimize test case creation. The Gtest platform guarantees
          100% coverage of the functionalities provided by the web application, the length of which
          can be adjusted by the number of predetermined reproduction steps. The usage of the
          service is as easy as apple pie, below are tips on how to use the tool. Have fun!!!
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button href="https://gtest.com/" target="_blank">
          <HomeIcon style={{ paddingRight: '0.5em' }} />
          Visit our website
        </Button>
      </CardActions>
    </Card>
  );
};
