import { fetchUtils } from 'react-admin';
import { fetchUtils as coreFetchUtils } from 'ra-core';
import { isArray, isNil } from 'lodash';
import { Auth } from 'aws-amplify';
import { authProvider } from '..';

export async function httpProvider(url: string, options: coreFetchUtils.Options = {}) {
  const token = await authProvider.getToken();

  if (isNil(token)) {
    return fetchUtils.fetchJson(url, options);
  }

  const initHeaders = {
    Accept: 'application/json',
  };
  const authorizationHeader = `Bearer ${token}`;
  setHeaders(options, initHeaders, authorizationHeader);
  await refreshAuthToken();

  return fetchUtils.fetchJson(url, options);
}

function setHeaders(
  options: coreFetchUtils.Options,
  initHeaders: { [key: string]: string },
  authorizationHeader: string,
) {
  if (!options.headers) {
    options.headers = new Headers(initHeaders);
  }

  if (options.headers instanceof Headers) {
    options.headers.set('Authorization', authorizationHeader);
  } else if (isArray(options.headers)) {
    options.headers.push(['Authorization', authorizationHeader]);
  } else {
    options.headers.Authorization = authorizationHeader;
  }
}

function refreshAuthToken() {
  // This method will automatically refresh the accessToken and idToken if tokens are expired and a valid refreshToken presented
  return Auth.currentSession();
}
