import React, { FC } from 'react';
import { Title, useTranslate } from 'react-admin';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { InfoCard } from './info-card';
import { Welcome } from './welcome';
import { getContentList } from './const';
import { Link } from './link';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  adresElem: {
    textAlign: 'left',
    fontStyle: 'normal',
    '& > *': {
      display: 'block',
    },
  },
});

export const Help: FC = props => {
  const translate = useTranslate();
  const contentList = getContentList();
  const classes = useStyles();

  return (
    <div>
      <Title title={translate('app.help.pageTitle')} />
      <Card>
        <CardContent className={classes.container}>
          <Typography className={classes.adresElem}>
            <span>gTest Technologies ltd.</span>
            <span>address :</span>
            <span>Kopernik Office Buildings, entrance E,</span>
            <span>Aleje Jerozolimskie 172,</span>
            <span>02-486 Warsaw</span>
            <span>Telephone no.</span>
            <Link linkIcon={<LocalPhoneIcon />} href="tel:224276370" title="22 427 63 70" />
            <Link linkIcon={<LocalPhoneIcon />} href="tel:0048533317618" title="+48 533 317 618" />
            <Link
              linkIcon={<AlternateEmailIcon />}
              href="mailto:atw@gtest.com"
              title="atw@gtest.com"
            />
          </Typography>
        </CardContent>
      </Card>
      <Welcome />
      {contentList.map((element, index: number) => (
        <div key={index} className="accordion-list__column">
          <InfoCard info={element} />
        </div>
      ))}
    </div>
  );
};
