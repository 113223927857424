import React, { FC, useState, useMemo } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { useNotify, SimpleForm, TextInput, useDataProvider, useTranslate } from 'react-admin';
import { isNil, isFunction } from 'lodash';
import { DialogTitle, Dialog, DialogContent, makeStyles, Button } from '@material-ui/core';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import { FeedbackType, AssessedStatus } from 'models';
import { getAssessedStatus } from 'utils';
import { projectTitleValidator } from 'services/validators';
import { ThumbButtons } from '../thumb-buttons';
import { FeedbackToolbar } from '../feedback-toolbar';

const useStyles = makeStyles({
  button: {
    border: '0px',
    backgroundColor: 'transparent',
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
});

type CommentButtonType = {
  feedback: FeedbackType;
  disabled: boolean;
  onActionSuccessful?: (updatedFeedback: FeedbackType) => void;
};

export const CommentButton: FC<CommentButtonType> = ({
  feedback,
  disabled,
  onActionSuccessful,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();

  const commentColor = useMemo(() => (!isNil(feedback.comment) ? 'secondary' : 'disabled'), [
    feedback.comment,
  ]);

  const commentInitialValue = useMemo(
    () => ({
      comment: feedback.comment,
      assessed: feedback.assessed,
    }),
    [feedback.comment, feedback.assessed],
  );

  const handleUpdateFeedback = (values: any) => {
    dataProvider
      .update('feedback', {
        id: feedback.id,
        data: values,
      })
      .then(({ data }: { data: FeedbackType }) => {
        notify('common.feedbackField.feedbackUpdate');
        setShowDialog(false);

        if (isFunction(onActionSuccessful)) {
          onActionSuccessful(data);
        }
      })
      .catch((error: Error) => {
        notify(`Comment approval error: ${error.message}`, 'warning');
      });
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleOpenClick = () => {
    setShowDialog(true);
  };

  const ThumbButtonsAdapter = ({ input, meta, ...rest }: FieldRenderProps<AssessedStatus, any>) => {
    const handleThumbUpClick = () =>
      input.onChange(getAssessedStatus(input.value, AssessedStatus.OK));

    const handleThumbDownClick = () =>
      input.onChange(getAssessedStatus(input.value, AssessedStatus.NOTOK));

    return (
      <ThumbButtons
        assessedStatus={input.value}
        disabled={disabled}
        onThumbUpClick={handleThumbUpClick}
        onThumbDownClick={handleThumbDownClick}
      />
    );
  };

  return (
    <>
      <Button className={classes.button} onClick={handleOpenClick}>
        <SpeakerNotesIcon color={commentColor} />
      </Button>
      <Dialog
        fullWidth={true}
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('common.feedbackField.ariaLabel')}
      >
        <DialogTitle>{translate('common.feedbackField.commentModalTitle')}</DialogTitle>
        <DialogContent>
          <SimpleForm
            initialValues={commentInitialValue}
            resource="feedback"
            toolbar={
              <FeedbackToolbar
                onClose={handleCloseClick}
                onSave={handleUpdateFeedback}
                disabled={disabled}
              />
            }
          >
            <Field name="assessed" component={ThumbButtonsAdapter} />
            <TextInput
              fullWidth={true}
              multiline={true}
              source="comment"
              disabled={disabled}
              validate={projectTitleValidator}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};
