import englishMessages from 'ra-language-english';

export const messages = {
  ...englishMessages,
  app: {
    login: {
      changePassword: {
        info: 'Please change your password to continue signing in.',
        emailLabel: 'E-mail',
      },
    },
    help: {
      label: 'Help',
      pageTitle: 'Welcome to the GTest testing tool panel',
    },
    layout: {
      customAppBar: {
        customUserMenu: {
          configurationMenu: {
            label: 'Configuration',
            title: 'Theme configuration',
            dark: 'Dark',
            light: 'Light',
          },
        },
      },
    },
  },
  common: {
    casesButton: {
      addToCases: 'Add to cases',
      removeFromCases: 'Remove from cases',
      onSuccessAdd: 'Paths added to cases',
      onFailureAdd: 'Error adding paths to cases',
      onSuccessRemove: 'Paths removed from cases',
      onFailureRemove: 'Error removing paths from cases',
    },
    deleteButton: {
      name: 'Delete',
    },
    feedbackField: {
      feedbackUpdate: 'Feedback updated.',
      ariaLabel: 'Add feedback',
      commentModalTitle: 'Feedback',
    },
    rowExpanded: {
      error: 'Error getting data',
      stepNumber: 'Step',
      humanReadable: 'Test step',
      expectedResult: 'Expected Result',
      data: 'Additional data',
      tableAriaLabel: 'Table with details',
      dropdown: {
        header: 'Show data',
      },
      screenshotImg: {
        alt: 'Screenshot',
        error: 'Error getting image',
      },
    },
    thumbButtons: {
      thumbUpLabel: 'thumb up',
      thumbDownLabel: 'thumb down',
    },
  },
  gTest: {
    cancelButtonCaption: 'Cancel',
    emailLabel: 'E-mail',
    projectsList: {
      title: 'List of projects',
      urlLabel: 'Start url',
      titleLabel: 'Title',
      authorLabel: 'Author',
      statusLabel: 'Status',
      pathsLabel: 'Paths',
      casesLabel: 'Cases',
      createdAtLabel: 'Creation Date',
      projectStatusWithTooltip: {
        DRAFT: 'Please review crawler parameters and run pre-scan or crawler',
        PRE_SCANNING: 'Pre-scan process is running. Please wait for results.',
        PENDING: 'Please define pre-scan test samples and run crawler',
        CRAWLING: 'Crawler is running. Please wait for results.',
        REVIEW: 'You can start review process and assess cases/add comments.',
        COMPLETED: "Project is completed. You can see it, but can't edit anything anymore",
      },
    },
    project: {
      postTitle: 'Project',
      tabLabels: {
        crawler: 'Crawler',
        paths: 'Paths',
        cases: 'Cases',
        feedback: 'Feedback',
      },
      form: {
        finishDateLabel: 'Crawling finish date',
        urlLabel: 'Start url',
        numberLabel: 'Max steps',
        validateUrlErrorMessage: 'Please enter valid URL',
        closeButtonLabel: 'Finish',
      },
      projectEdit: {
        messageCompleted: 'Project in status COMPLETED is disabled for editing.',
      },
      crawlerTab: {
        startPreScanBtnLabel: 'Start pre-scan',
        startCrawlerSimpleBtnLabel: 'Start simple crawl',
        startCrawlerExtendedBtnLabel: 'Start extended crawl',
        stopCrawlerBtnLabel: 'Stop crawler',
        startNotifySuccess: 'Crawler started',
        stopNotifySuccess: 'Crawler stopped',
        startNotifyFailure: 'Start crawler error: %{errorMessage}',
        stopNotifyFailure: 'Stop crawler error: %{errorMessage}',
        loaderText: 'Crawler is running:',
        preScanForm: {
          tooltip:
            'Please remember to fill out the forms correctly to achieve better results in generating test cases.',
          description: 'Description',
          process: 'Process',
          rowExpanded: {
            arrayInputLabel: 'Steps:',
            lookup: 'Lookup:',
            sample: 'Sample:',
            parameterUpdated: 'Parameter updated',
            parameterUpdateError: 'Parameter update error: %{errorMessage}',
          },
        },
        crawlingStats: {
          notAvailable: 'Crawling statistics will be available after crawling is done.',
          header: 'Crawling statistics',
          status: 'Current status:',
          lastProcessAt: 'Last process at:',
          totalRuntime: 'Total runtime:',
          phase: 'Phase',
          startedAt: 'Started at:',
          finishedAt: 'Finished at:',
          total: 'Total time:',
        },
      },
      pathsTab: {
        newDraftStatus: 'Please run crawler',
        crawlingStatus: 'Crawler is running. Please wait for results.',
        preScanStatus: 'Pre-scan process is running. Please wait for results.',
        pendingStatus: 'Paths will be available after pre-scan data is saved and crawling is done.',
        defaultStatus: 'Please run crawler',
        errorMessage: 'Error getting data',
        pathsList: {
          allAsCasesButton: 'Add all filtered paths to cases',
          successMessage: 'Added all filtered paths to cases',
          errorMessage: 'Error at adding all filtered paths to cases',
          title: ' - Generated paths',
          id: 'Id',
          stepGoal: 'Step goal',
          steps: 'Steps',
          score: 'Score',
          case: 'Case',
          feedback: 'Feedback',
        },
        pathsFilter: {
          withCase: 'With case',
          scoreThreshold: 'Score',
          elementTypes: 'Element types',
        },
      },
      casesTab: {
        notSentStatus: 'Please run crawler',
        preScanStatus: 'Pre-scan process is running. Please wait for results.',
        pendingStatus: 'Cases will be available after pre-scan data is saved and crawling is done.',
        crawlingStatus: 'Crawler is running. Please wait for results.',
        defaultStatus: 'Please run crawler',
        casesList: {
          title: ' - Generated cases',
          id: 'Id',
          path: 'Path',
          steps: 'Steps',
          score: 'Score',
          feedback: 'Feedback',
        },
      },
      feedbackTab: {
        notAvailable: 'Feedback review will be available after crawling is done.',
        feedbackStats: {
          cases: 'Cases',
          comments: 'comments',
        },
        feedbackList: {
          title: ' - Feedback',
          subject: 'Subject',
          feedback: 'Feedback',
          comment: 'Comment',
        },
      },
    },
  },
};
