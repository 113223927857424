import React, { FC } from 'react';
import { useDataProvider, useTranslate, useRedirect, useNotify } from 'react-admin';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { constProvider } from 'providers/const';

type DeleteButtonProps = {
  resource: string;
  id: string;
};

const useStyles = makeStyles({
  button: {
    color: 'rgb(220, 0, 78)',
    borderColor: 'rgb(220, 0, 78)',
  },
});

export const DeleteButton: FC<DeleteButtonProps> = ({ resource, id }) => {
  const redirectTo = useRedirect();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();

  const onClick = () => {
    dataProvider
      .delete(resource, { id })
      .then(() => {
        redirectTo(`/${constProvider.RESOURCES.PROJECT}`);
      })
      .catch((error: Error) => {
        notify(error);
      });
  };

  return (
    <Button variant="outlined" className={classes.button} onClick={onClick}>
      {translate('common.deleteButton.name')}
    </Button>
  );
};
