import React, { FC } from 'react';
import { UserMenu } from 'react-admin';
import { ConfigurationMenu } from './configuration-menu';

export const CustomUserMenu: FC = (props: any) => {
  return (
    <UserMenu {...props}>
      <ConfigurationMenu />
    </UserMenu>
  );
};
