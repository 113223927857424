import React, { FC } from 'react';
import { Button } from 'react-admin';

type CustomCloseButtonProps = {
  onClick: () => void;
};

export const CustomCloseButton: FC<CustomCloseButtonProps> = ({ onClick }) => (
  <Button label="ra.action.cancel" onClick={onClick} />
);
