export enum ProjectStatus {
  DRAFT = 'DRAFT',
  PRE_SCANNING = 'PRE_SCANNING',
  PENDING = 'PENDING',
  CRAWLING = 'CRAWLING',
  REVIEW = 'REVIEW',
  COMPLETED = 'COMPLETED',
}

export type ProjectEntity = {
  id: string;
  name: string;
  status: ProjectStatus;
  createdBy: string;
  cases: number;
  crawlingStartDate: string;
  createdAt: string;
  feedback: string;
  paths: number;
  crawler: {
    maxPathLength: number;
    url: string;
  };
};
