import React, { FC } from 'react';
import { Field, FieldInputProps } from 'react-final-form';
import { CustomSlider } from './custom-slider';

type CustomSliderProps = ReactAdminFilterProps & {
  label: string;
  source: string;
  min: number;
  max: number;
  step: number;
};

export const Slider: FC<CustomSliderProps> = ({ source, label, min, max, step }) => {
  return (
    <Field name={source}>
      {(props: FieldInputProps<number | number[]>) => (
        <CustomSlider label={label} min={min} max={max} step={step} {...props} />
      )}
    </Field>
  );
};
