import React, { FC, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate, useDataProvider } from 'react-admin';
import { includes } from 'lodash';
import * as Types from 'Types';
import { ProjectEntity, ProjectStatus } from 'models';
import { getPathName } from 'utils';
import { Placeholder } from 'components/common/placeholder';
import { FeedbackStatsResponse } from './feedback-tab.model';
import { FeedbackList } from './feedback-list';
import { FeedbackStats } from './feedback-stats';

type FeedbackTabProps = ReactAdminTabPanelItemProps & {
  project: ProjectEntity;
};

export const FeedbackTab: FC<FeedbackTabProps> = ({ project, ...props }) => {
  const translate = useTranslate();
  const router = useSelector((state: Types.RootState) => state.router);
  const dataProvider = useDataProvider();
  const [isStatsLoading, setIsStatsLoading] = useState<boolean>(true);
  const [statsError, setStatsError] = useState<Error | undefined>();
  const [stats, setStats] = useState<FeedbackStatsResponse | undefined>();

  const handleGetFeedbackStats = useCallback(() => {
    dataProvider
      .getOne(`${getPathName(router.location)}`, { id: 'feedback-stats' })
      .then(({ data }: { data: FeedbackStatsResponse }) => {
        setIsStatsLoading(false);
        setStats(data);
      })
      .catch((e: Error) => {
        setIsStatsLoading(false);
        setStatsError(e);
      });
  }, [dataProvider, router.location]);

  useEffect(() => {
    handleGetFeedbackStats();
  }, [dataProvider, router.location, handleGetFeedbackStats]);

  const renderNotAvailableInfo = useCallback(
    () => <Placeholder message={translate('gTest.project.feedbackTab.notAvailable')} />,
    [translate],
  );

  const renderTab = useCallback(
    () => (
      <>
        <FeedbackStats loading={isStatsLoading} data={stats} error={statsError} />
        <FeedbackList {...props} onActionSuccessful={handleGetFeedbackStats} />
      </>
    ),
    [isStatsLoading, stats, statsError, props, handleGetFeedbackStats],
  );

  return includes([ProjectStatus.REVIEW, ProjectStatus.COMPLETED], project.status)
    ? renderTab()
    : renderNotAvailableInfo();
};
