export const darkTheme = {
  palette: {
    primary: {
      light: '#2196f3', // jasny niebieski
      main: '#90caf9', // błękitny
      dark: '#81b5e0', // błękitny ciemny
    },
    secondary: {
      main: '#067619', // zielony
    },
    type: 'dark',
  },
};

export const lightTheme = {
  palette: {
    primary: {
      light: '#5f5fc4', // fioletowy
      main: '#3f51b5', // ciemny niebieski
      dark: '#001064', // granatowy
      contrastText: '#fff',
    },
    secondary: {
      main: '#2196f3', // jasny niebieski
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
};
