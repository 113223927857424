import { DataProvider } from 'ra-core';
import { getEntityIdFromUrl } from 'utils';
import { constProvider } from '../../const';
import { historyProvider } from '../../history';

export const getProjectGetList = (
  resource: string,
  params: any,
  baseDataProvider: DataProvider,
) => {
  const projectResource = constProvider.RESOURCES.PROJECT;
  const projectPathsResource = constProvider.RESOURCES.PROJECT_PATHS;
  const projectCasesResource = constProvider.RESOURCES.PROJECT_CASES;
  const projectFeedbackResource = constProvider.RESOURCES.PROJECT_FEEDBACK_LIST;
  const projectPreScanFormsResource = constProvider.RESOURCES.PROJECT_PRE_SCAN_FORMS;

  if (resource === projectPathsResource) {
    return baseDataProvider.getList(
      `${projectResource}/${getEntityIdFromUrl(historyProvider.location)}/${projectPathsResource}`,
      params,
    );
  }

  if (resource === projectCasesResource) {
    return baseDataProvider.getList(
      `${projectResource}/${getEntityIdFromUrl(historyProvider.location)}/${projectCasesResource}`,
      params,
    );
  }

  if (resource === projectFeedbackResource) {
    return baseDataProvider.getList(
      `${projectResource}/${getEntityIdFromUrl(
        historyProvider.location,
      )}/${projectFeedbackResource}`,
      params,
    );
  }

  if (resource === projectPreScanFormsResource) {
    return baseDataProvider.getList(
      `${projectResource}/${getEntityIdFromUrl(
        historyProvider.location,
      )}/${projectPreScanFormsResource}`,
      params,
    );
  }
};
