import React, { FC, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { get, isNil, matches, constant, stubTrue, cond } from 'lodash';
import { Dropdown } from './dropdown';
import { ScreenshotImg } from './screenshot-img';
import { RowExpandedProps, RowType } from './row-expanded.models';

const useStyles = makeStyles({
  head: {
    backgroundColor: '#2196f3',
    color: 'white',
  },
  cell: {
    verticalAlign: 'top',
    whiteSpace: 'pre-wrap',
  },
  error: {
    color: 'red',
  },
});

const headerTitles: string[] = ['stepNumber', 'humanReadable', 'expectedResult', 'data'];

export const RowExpanded: FC<RowExpandedProps> = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const path = get(record, 'pathSteps');

  const cellValue = useCallback(
    (title: string, row: any) => {
      const value = row[title];

      const detailsInDropdown = () => {
        if (value) {
          try {
            return <Dropdown data={JSON.stringify(JSON.parse(value), null, '\t')} />;
          } catch (e) {
            return <span className={classes.error}>{translate('common.rowExpanded.error')}</span>;
          }
        }
      };

      const detailsWithImg = (text?: string, imgName?: string) => {
        return isNil(text) ? (
          ''
        ) : (
          <div>
            <span>{text}</span>
            {imgName && <ScreenshotImg id={imgName} />}
          </div>
        );
      };

      const displayBasedOnData = cond([
        [matches('stepNumber'), constant(<span>{1 + Number(row[title])}</span>)],
        [matches('humanReadable'), constant(detailsWithImg(value, row.screenShotBefore))],
        [matches('expectedResult'), constant(detailsWithImg(value, row.screenShotAfter))],
        [matches('data'), constant(detailsInDropdown())],
        [stubTrue, constant('')],
      ]);

      return displayBasedOnData(title);
    },
    [classes.error, translate],
  );

  return (
    <div>
      {path && (
        <TableContainer component={Paper}>
          <Table aria-label={translate('common.rowExpanded.tableAriaLabel')}>
            <TableHead>
              <TableRow>
                {headerTitles.map(title => {
                  return (
                    <TableCell key={title} className={classes.head}>
                      {translate(`common.rowExpanded.${title}`)}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {path.map((row: RowType) => (
                <TableRow key={row.id}>
                  {headerTitles.map(title => {
                    return (
                      <TableCell key={`${row.id}${title}`} className={classes.cell}>
                        <div>{cellValue(title, row)}</div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
