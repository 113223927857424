import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  FormWithRedirect,
  TextInput,
  SaveButton,
  useNotify,
  useDataProvider,
  useTranslate,
  useRedirect,
} from 'react-admin';
import { pick, isNil } from 'lodash';
import { Box, Toolbar, Grid, Chip, Tabs, Tab, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ProjectEntity, ProjectStatus } from 'models';
import { constProvider } from 'providers/const';
import { historyProvider } from 'providers/history';
import { projectTitleValidator } from 'services/validators';
import { dateTimeFormat, dateTimeParse } from 'utils';
import { DeleteButton } from 'components/common/delete-button';
import { TAB_PANELS } from './project-edit-form.const';
import { TabPanel } from './tab-panel';
import { CompleteProjectButton } from './complete-project-button';

const useStyles = makeStyles({
  container: {
    padding: '10px 30px 0',
  },
  badge: {
    backgroundColor: '#3f51b5',
    color: 'white',
    marginLeft: '10px',
  },
  tabs: {
    margin: '30px',
    marginBottom: 0,
    border: '1px solid #ddd',
  },
  tabPanel: {
    margin: '0 30px',
    border: '1px solid #ddd',
    borderTop: 0,
  },
  toolbar: {
    padding: '0 30px',
  },
  cancelButton: {
    marginRight: '20px',
  },
});

type ProjectEditFormProps = ReactAdminEditChildrenProps & {
  project: ProjectEntity;
  statusDisabled: boolean;
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ProjectEditForm: FC<ProjectEditFormProps> = props => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const translate = useTranslate();
  const redirectTo = useRedirect();

  const [selectedTabValue, setSelectedTabValue] = useState<number>(0);

  const pathsAmount = useMemo(() => props.project && props.project.paths, [props.project]);

  const casesAmount = useMemo(() => props.project && props.project.cases, [props.project]);

  const onCancelClick = useCallback(() => {
    redirectTo(`/${constProvider.RESOURCES.PROJECT}`);
  }, [redirectTo]);

  const handleSave = useCallback(
    values => {
      dataProvider
        .update(constProvider.RESOURCES.PROJECT, {
          id: values.id,
          data: {
            ...pick(values, 'id', 'name', 'crawler'),
          },
        })
        .then((data: ProjectEntity) => {
          notify('ra.notification.updated', 'info', {
            smart_count: 1,
          });
        })
        .catch((error: Error) => {
          notify('ra.message.error', 'warning', {
            errorMessage: error.message,
          });
        });
    },
    [dataProvider, notify],
  );

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      historyProvider.push({ search: '' });
      setSelectedTabValue(newValue);
    },
    [setSelectedTabValue],
  );

  const getLabelWithBadge = useCallback(
    (label: string, counter?: number) => {
      const labelTranslated = translate(label);
      const shouldDisplayCounter = !isNil(counter) && counter > 0;

      return (
        <span>
          {labelTranslated}
          {shouldDisplayCounter && <Chip size="small" className={classes.badge} label={counter} />}
        </span>
      );
    },
    [translate, classes],
  );

  const renderTabs = () => (
    <Tabs
      className={classes.tabs}
      value={selectedTabValue}
      onChange={handleTabChange}
      aria-label="project tabs"
    >
      <Tab label={translate('gTest.project.tabLabels.crawler')} {...a11yProps(0)} />
      <Tab
        label={getLabelWithBadge('gTest.project.tabLabels.paths', pathsAmount)}
        {...a11yProps(1)}
      />
      <Tab
        label={getLabelWithBadge('gTest.project.tabLabels.cases', casesAmount)}
        {...a11yProps(2)}
      />
      <Tab label={translate('gTest.project.tabLabels.feedback')} {...a11yProps(3)} />
    </Tabs>
  );

  const renderTabPanels = () => (
    <>
      {TAB_PANELS.map((component, index) => {
        const Component = component;

        return (
          <TabPanel className={classes.tabPanel} key={index} index={index} value={selectedTabValue}>
            <Component project={props.project} statusDisabled={props.statusDisabled} {...props} />
          </TabPanel>
        );
      })}
    </>
  );

  const renderForm = (formProps: RactAdminFormWithRedirectRenderProps) => {
    const { project, statusDisabled } = props;

    const isLoading =
      !isNil(project) && (project.status === ProjectStatus.CRAWLING || statusDisabled);

    return (
      <form>
        <Grid className={classes.container} container={true} spacing={3}>
          <Grid item={true} xs={4}>
            <TextInput
              validate={projectTitleValidator}
              fullWidth={true}
              source="name"
              disabled={isLoading}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <TextInput fullWidth={true} source="createdBy" disabled={true} />
          </Grid>
          <Grid item={true} xs={3}>
            <TextInput
              fullWidth={true}
              source="createdAt"
              disabled={true}
              format={dateTimeFormat}
              parse={dateTimeParse}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <TextInput fullWidth={true} source="status" disabled={true} initialValue="" />
          </Grid>
        </Grid>
        {renderTabs()}
        {renderTabPanels()}
        <Toolbar className={classes.toolbar}>
          {project.status !== ProjectStatus.CRAWLING &&
            project.status !== ProjectStatus.PRE_SCANNING && (
              <DeleteButton resource={constProvider.RESOURCES.PROJECT} id={props.project.id} />
            )}
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button
              variant="outlined"
              color="primary"
              className={classes.cancelButton}
              onClick={onCancelClick}
            >
              {translate('gTest.cancelButtonCaption')}
            </Button>
            {project.status === ProjectStatus.REVIEW && <CompleteProjectButton {...props} />}
            <SaveButton
              invalid={formProps.invalid}
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              onSave={handleSave}
              disabled={isLoading}
            />
          </Box>
        </Toolbar>
      </form>
    );
  };

  return <FormWithRedirect {...props} render={renderForm} />;
};
