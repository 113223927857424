export type CrawlingProgress = {
  x: number;
  y: number;
  phase: PhaseType;
};

export enum PhaseType {
  FINALIZATION = 'FINALIZATION',
  PATHS_GENERATION = 'PATHS_GENERATION',
  DISCOVERY = 'DISCOVERY',
  INITIALIZATION = 'INITIALIZATION',
  FINISHED = 'FINISHED',
  STARTING_CRAWLER = 'STARTING_CRAWLER',
  SCORING = 'SCORING',
}
