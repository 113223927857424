import React, { FC } from 'react';
import { useTranslate, Loading, Error as ErrorMessage } from 'react-admin';
import { Grid, Typography, Icon, makeStyles } from '@material-ui/core';
import { FeedbackStatsResponse } from '../feedback-tab.model';

type FeedbackStatsProps = {
  loading: boolean;
  error?: Error;
  data?: FeedbackStatsResponse;
};

const useStyles = makeStyles(() => ({
  thumbsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  thumbIcon: {
    marginRight: '4px',
    fontSize: '16px',
  },
}));

export const FeedbackStats: FC<FeedbackStatsProps> = ({ data, loading, error }) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (loading) return <Loading />;

  if (error) return <ErrorMessage />;

  if (!data) return null;

  return (
    <Grid container={true} spacing={3} justify="space-between" alignItems="flex-end">
      <Grid item={true} xs={2}>
        <Typography>{translate('gTest.project.feedbackTab.feedbackStats.cases')}</Typography>
      </Grid>
      <Grid item={true} xs={2}>
        <Typography>{data.cases || 0}</Typography>
      </Grid>
      <Grid item={true} xs={2}>
        <div className={classes.thumbsContainer}>
          <Icon className={classes.thumbIcon}>thumb_up</Icon> <span>{data.okCount || 0}</span>
        </div>
      </Grid>
      <Grid item={true} xs={2}>
        <div className={classes.thumbsContainer}>
          <Icon className={classes.thumbIcon}>thumb_down</Icon> <span>{data.notOkCount || 0}</span>
        </div>
      </Grid>
      <Grid item={true} xs={2}>
        <Typography>
          {data.comments || 0} {translate('gTest.project.feedbackTab.feedbackStats.comments')}
        </Typography>
      </Grid>
    </Grid>
  );
};
