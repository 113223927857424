import React, { FC } from 'react';
import { useTranslate, useRefresh, List, TextField, FunctionField, Datagrid } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { CaseType } from 'models';
import { constProvider } from 'providers';
import { FeedbackField } from 'components/common/feedback-field';
import { RowExpanded } from 'components/common/row-expanded';
import { renderPathsField, renderScoreField } from './cases-list.utils';
import { CasesFilter } from './cases-filter';
import { CasesBulkActionButtons } from './cases-bulk-action-buttons';

const useStyles = makeStyles({
  textField: {
    maxWidth: '40px',
    display: 'block',
    textAlign: 'center',
  },
  idField: {
    minWidth: '200px',
  },
});

const filterDefaultValues = {
  scoreThreshold: [0.5, 1],
  elementTypes: constProvider.ELEMENT_TYPES_DEFAULT_SELECTED,
};

type CasesListProps = ReactAdminListProps & {
  statusDisabled: boolean;
};

export const CasesList: FC<CasesListProps> = ({ statusDisabled, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const refresh = useRefresh();

  const onFeedbackActionSuccessful = () => {
    refresh();
  };

  const renderFeedbackField = (record: CaseType) => {
    const { feedback } = record;
    return (
      <FeedbackField
        feedback={feedback}
        disabled={statusDisabled}
        onActionSuccessful={onFeedbackActionSuccessful}
      />
    );
  };

  return (
    <List
      {...props}
      resource={constProvider.RESOURCES.PROJECT_CASES}
      hasList={false}
      hasEdit={false}
      hasShow={false}
      hasCreate={false}
      title={translate('gTest.project.casesTab.casesList.title')}
      filters={<CasesFilter />}
      filterDefaultValues={filterDefaultValues}
      bulkActionButtons={
        statusDisabled ? (
          false
        ) : (
          <CasesBulkActionButtons selectionResource={constProvider.RESOURCES.PROJECT_CASES} />
        )
      }
    >
      <Datagrid expand={<RowExpanded />}>
        <TextField
          source="id"
          label={translate('gTest.project.casesTab.casesList.id')}
          className={classes.idField}
        />
        <FunctionField
          label={translate('gTest.project.casesTab.casesList.path')}
          render={renderPathsField}
        />
        <TextField
          source="steps"
          sortable={true}
          label={translate('gTest.project.casesTab.casesList.steps')}
          className={classes.textField}
        />
        <FunctionField
          label={translate('gTest.project.casesTab.casesList.score')}
          render={renderScoreField}
          sortable={true}
          source="score"
        />
        <FunctionField
          label={translate('gTest.project.pathsTab.pathsList.feedback')}
          render={renderFeedbackField}
          disabled={statusDisabled}
        />
      </Datagrid>
    </List>
  );
};
