import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, useDataProvider, useRedirect, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';
import * as Types from 'Types';
import { ProjectEntity } from 'models';
import { getPathName } from 'utils';

type CompleteProjectButtonProps = ReactAdminEditChildrenProps & {};

const useStyles = makeStyles({
  completeButton: {
    marginRight: '20px',
  },
  icon: {
    marginRight: '8px',
  },
});

export const CompleteProjectButton: FC<CompleteProjectButtonProps> = props => {
  const classes = useStyles();
  const router = useSelector((state: Types.RootState) => state.router);
  const dataProvider = useDataProvider();
  const redirectTo = useRedirect();
  const redirect = useRedirect();
  const notify = useNotify();
  const { basePath } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCompleteProject = useCallback(() => {
    setIsLoading(true);

    dataProvider
      .update(getPathName(router.location), {
        id: 'complete',
        data: {},
      })
      .then((data: ProjectEntity) => {
        setIsLoading(false);
        notify('ra.notification.updated', 'info', {
          smart_count: 1,
        });
        redirectTo(redirect, basePath, data.id, data);
      })
      .catch((error: Error) => {
        setIsLoading(false);
        notify('ra.message.error', 'warning', {
          errorMessage: error.message,
        });
      });
  }, [redirectTo, redirect, router, basePath, dataProvider, notify]);

  return (
    <Button
      className={classes.completeButton}
      disabled={isLoading}
      variant="contained"
      onClick={handleCompleteProject}
      children={
        <Icon className={classes.icon} fontSize="small">
          done
        </Icon>
      }
      label={'gTest.project.form.closeButtonLabel'}
    />
  );
};
