import React, { FC, useCallback } from 'react';
import { Slider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldInputProps } from 'react-final-form';

const useStyles = makeStyles({
  slider: {
    width: '200px',
    marginLeft: '20px',
  },
});

type CustomSliderProps = ReactAdminFilterProps & {
  label: string;
  min: number;
  max: number;
  step: number;
  input: FieldInputProps<number | number[]>;
};

export const CustomSlider: FC<CustomSliderProps> = ({ label, min, max, step, input }) => {
  const classes = useStyles();
  const handleChange = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: number | number[]) => {
      input.onChange(newValue);
    },
    [input],
  );

  return (
    <>
      <Typography color="textPrimary" id="range-slider">
        {label}
      </Typography>
      <Slider
        onChange={handleChange}
        name={input.name}
        value={input.value}
        min={min}
        max={max}
        step={step}
        marks={true}
        valueLabelDisplay="on"
        className={classes.slider}
        aria-labelledby="range-slider"
      />
    </>
  );
};
