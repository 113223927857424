import React, { FC } from 'react';
import { Datagrid, TextField, List, useTranslate } from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';
import { constProvider } from 'providers/const';
import { RowExpanded } from './row-expanded';

type PreScanFormProps = {
  saving: boolean;
  save: boolean;
  statusDisabled: boolean;
};

const useStyles = makeStyles({
  container: {
    marginBottom: '30px',
  },
  tooltip: {
    marginTop: '40px',
    color: '#2196f3',
  },
});
export const PreScanForm: FC<PreScanFormProps> = ({ statusDisabled, saving, save, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.tooltip} variant="subtitle1">
        {translate('gTest.project.crawlerTab.preScanForm.tooltip')}
      </Typography>
      <List
        {...props}
        resource={constProvider.RESOURCES.PROJECT_PRE_SCAN_FORMS}
        hasList={false}
        hasEdit={false}
        hasShow={false}
        hasCreate={false}
        bulkActionButtons={false}
        title="."
        pagination={null}
        exporter={false}
        className={classes.container}
        filters={null}
      >
        <Datagrid expand={<RowExpanded statusDisabled={statusDisabled} {...props} />}>
          <TextField
            source="description"
            label={translate('gTest.project.crawlerTab.preScanForm.description')}
            sortable={false}
          />
          <TextField
            source="process"
            label={translate('gTest.project.crawlerTab.preScanForm.process')}
            sortable={false}
          />
        </Datagrid>
      </List>
    </div>
  );
};
