import React, { FC } from 'react';
import { FeedbackType } from 'models';
import { makeStyles } from '@material-ui/core/styles';
import { CommentButton } from './comment-button';
import { ThumbButtons } from './thumb-buttons';

const useStyles = makeStyles({
  feedbackCell: {
    width: '100px',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

type FeedbackFieldProps = {
  feedback: FeedbackType;
  disabled: boolean;
  onActionSuccessful?: (updatedFeedback: FeedbackType) => void;
};

export const FeedbackField: FC<FeedbackFieldProps> = ({
  feedback,
  disabled,
  onActionSuccessful,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.feedbackCell}>
      <ThumbButtons
        feedback={feedback}
        disabled={disabled}
        onActionSuccessful={onActionSuccessful}
      />
      <CommentButton
        feedback={feedback}
        disabled={disabled}
        onActionSuccessful={onActionSuccessful}
      />
    </div>
  );
};
