import { required, email } from 'react-admin';

export const isValidUrl = (error: string) => (value: string) => {
  try {
    // tslint:disable-next-line:no-unused-expression
    new URL(value);
  } catch (_) {
    return error;
  }
};

export const requiredValidator = [required()];

export const emailValidator = [required(), email()];
