import React, { FC } from 'react';
import { Filter, TextInput } from 'react-admin';

type ProjectFilterProps = {
  [k: string]: string;
};

export const ProjectsFilter: FC<ProjectFilterProps> = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn={true} />
    </Filter>
  );
};
