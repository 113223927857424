import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { UserPermissions } from './auth-provider.models';

import './auth.config';

const PERMISSIONS_LOCAL_STORAGE_KEY = 'permissions';

export const changePassword = (user: any, password: string, requiredAttributes: any) =>
  Auth.completeNewPassword(user, password, requiredAttributes);

export const login = async (params: any): Promise<CognitoUser | any> => {
  if (params instanceof CognitoUser) {
    return params;
  }

  const error = getError(params);

  if (error) {
    return Promise.reject(error);
  }

  const { username, password } = params;

  return Auth.signIn(username, password);
};

export const logout = (): Promise<void> => {
  return Auth.signOut();
};

const getError = (params: any) => {
  return params.error;
};

export class AuthProvider {
  private get permissions(): UserPermissions[] {
    const rawData = localStorage.getItem(PERMISSIONS_LOCAL_STORAGE_KEY);
    return rawData ? (JSON.parse(rawData) as UserPermissions[]) : [];
  }

  private set permissions(value: UserPermissions[]) {
    localStorage.setItem(PERMISSIONS_LOCAL_STORAGE_KEY, JSON.stringify(value));
  }

  public async login(params: any) {
    await login(params);
    // TODO decode permissions from token
    this.permissions = [UserPermissions.NORMAL_USER];
  }

  public async logout(): Promise<void> {
    await logout();
    this.permissions = [];
  }

  public async checkAuth(): Promise<void> {
    try {
      const currentSession = await this.getCurrentSession();

      return currentSession ? Promise.resolve() : Promise.reject();
    } catch (e) {
      return Promise.reject();
    }
  }

  public async checkError(): Promise<void> {
    // 4xx errors should not log the user out.
    return Promise.resolve();
  }

  public async getPermissions(): Promise<UserPermissions[]> {
    return Promise.resolve(this.permissions);
  }

  public async getToken() {
    try {
      const currentSession = await this.getCurrentSession();
      const accessToken = currentSession.getAccessToken();
      return accessToken.getJwtToken();
    } catch (e) {
      return undefined;
    }
  }

  private async getCurrentSession(): Promise<CognitoUserSession> {
    try {
      const session = await Auth.currentSession();

      if (!session) {
        return Promise.reject();
      }

      return session;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
