import React, { FC } from 'react';
import { SaveButton, Toolbar } from 'react-admin';

type SamplesToolbarProps = ReactAdminToolbarProps & {
  onSave: () => void;
};

export const SamplesToolbar: FC<SamplesToolbarProps> = ({ onSave, disabled, ...props }) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" onSave={onSave} disabled={disabled} />
    </Toolbar>
  );
};
