import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  title: {
    width: '200px',
    marginBottom: '20px',
  },
  inputs: {
    maxWidth: '80%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    marginRight: '40px',
    minWidth: '200px',
  },
  arrayInput: {
    '& label': {
      fontSize: '24px',
      fontWeight: 'bold',
    },
  },
  row: {
    display: 'flex',
    marginTop: '16px',
    marginLeft: '30px',
    '& label': {
      fontSize: '20px',
      fontWeight: 'normal',
    },
  },
});
