export enum AssessedStatus {
  NONE = 'NONE',
  OK = 'OK',
  NOTOK = 'NOTOK',
}

export type FeedbackType = {
  assessed: AssessedStatus;
  comment: string;
  id: string;
};

export enum FeedbackSubjectType {
  PATH = 'PATH',
  CASE = 'CASE',
}
