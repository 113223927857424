import React, { FC, useMemo, useCallback } from 'react';
import { useTranslate, Loading, Error as ErrorMessage } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { dateTimeFormat } from 'utils';
import { CrawlingStatsResponse } from '../crawling-stats.model';
import { useStyles } from './stats.styles';

type StatsProps = {
  loading: boolean;
  error?: Error;
  data?: CrawlingStatsResponse;
};

export const Stats: FC<StatsProps> = ({ data, loading, error }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const convertMS = useCallback((ms: number) => {
    let s = Math.floor(ms / 1000);
    let m = Math.floor(s / 60);
    s = s % 60;
    let h = Math.floor(m / 60);
    m = m % 60;
    const d = Math.floor(h / 24);
    h = h % 24;
    h += d * 24;
    return h + ':' + m + ':' + s;
  }, []);

  const getDate = useCallback((value: string) => {
    return dateTimeFormat(value);
  }, []);

  const arrayOfDetails = useMemo(() => data && [data.discovery, data.generation, data.scoring], [
    data,
  ]);

  if (loading) return <Loading />;

  if (error) return <ErrorMessage />;

  if (!data) return null;

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h5" gutterBottom={true}>
          {translate('gTest.project.crawlerTab.crawlingStats.header')}
        </Typography>
        <Divider />
        <Typography gutterBottom={true}>
          <span className={classes.line}>
            <span className={classes.title}>
              {translate('gTest.project.crawlerTab.crawlingStats.status')}
            </span>
            <span>{data.currentStatus}</span>
          </span>
          <span className={classes.line}>
            <span className={classes.title}>
              {translate('gTest.project.crawlerTab.crawlingStats.lastProcessAt')}
            </span>
            <span>{getDate(data.lastProgressAt)}</span>
          </span>
          <span className={classes.line}>
            <span className={classes.title}>
              {translate('gTest.project.crawlerTab.crawlingStats.totalRuntime')}
            </span>
            <span>{convertMS(data.runtimeMs)}</span>
          </span>
        </Typography>

        <div className={classes.container}>
          {arrayOfDetails &&
            arrayOfDetails.map(element => {
              return (
                <div key={element.phase} className={classes.elementContainer}>
                  <Typography gutterBottom={true}>
                    {bull} {translate('gTest.project.crawlerTab.crawlingStats.phase')}{' '}
                    {element.phase}
                  </Typography>
                  <Typography>
                    <span className={classes.title}>
                      {translate('gTest.project.crawlerTab.crawlingStats.startedAt')}{' '}
                    </span>
                    {element.startedAt ? getDate(element.startedAt) : '-'}
                  </Typography>
                  <Typography>
                    <span className={classes.title}>
                      {translate('gTest.project.crawlerTab.crawlingStats.finishedAt')}
                    </span>
                    {element.finishedAt ? getDate(element.finishedAt) : '-'}
                  </Typography>
                  <Typography>
                    <span className={classes.title}>
                      {translate('gTest.project.crawlerTab.crawlingStats.total')}
                    </span>
                    {convertMS(element.timeMs)}
                  </Typography>
                </div>
              );
            })}
        </div>
      </CardContent>
    </Card>
  );
};
