import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, useTranslate } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import LabelIcon from '@material-ui/icons/Label';
import { routerPaths } from 'router/customRoutes';

type CustomMenuProps = {
  onMenuClick: () => void;
  logout: object;
};

export const CustomMenu: FC<CustomMenuProps> = ({ onMenuClick, logout }) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const projectsResource =
    resources && resources.find((element: any) => element.options.label === 'Projects');

  return (
    <div>
      {projectsResource && (
        <MenuItemLink
          key={projectsResource.name}
          to={`/${projectsResource.name}`}
          primaryText={
            (projectsResource.options && projectsResource.options.label) || projectsResource.name
          }
          leftIcon={projectsResource.icon ? <projectsResource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      <MenuItemLink
        to={routerPaths.HELP}
        primaryText={translate('app.help.label')}
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  );
};
