import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '275px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  line: {
    display: 'block',
    marginTop: '10px',
  },
  title: {
    color: theme.palette.text.secondary,
    display: 'inline-block',
    minWidth: '140px',
  },
  container: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  elementContainer: {
    marginRight: '80px',
    marginBottom: '20px',
    minWidth: '300px',
  },
}));
