import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CustomUserMenu } from './customUserMenu';
import logo from './logo.png';

export const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: '12px',
  },
  spacer: {
    flex: 1,
  },
  image: {
    maxHeight: '30px',
    padding: '10px',
  },
});

export const CustomAppBar = (props: any) => {
  const classes = useStyles();

  return (
    <AppBar {...props} userMenu={<CustomUserMenu />} color="secondary">
      <Typography variant="h6" className={classes.title} id="react-admin-title" />
      <span className={classes.spacer} />
      <img src={logo} alt="gtest" className={classes.image} />
    </AppBar>
  );
};
