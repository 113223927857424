import React, { FC, useMemo } from 'react';
import { SelectArrayInput } from 'react-admin';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MultiselectProps, Choice } from './multiselect.models';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: '20px',
    '& label': {
      display: 'none',
    },
    '& p': {
      display: 'none',
    },
  },
  customLabel: {
    marginLeft: '20px',
  },
});

export const Multiselect: FC<MultiselectProps> = ({ label, source, alwaysOn, choices }) => {
  const classes = useStyles();
  const arrayOfChoices = useMemo(
    () =>
      choices.map((element: Choice) => {
        return {
          id: element.value,
          name: element.name,
        };
      }),
    [choices],
  );

  return (
    <>
      <Typography color="textPrimary" className={classes.customLabel}>
        {label}
      </Typography>
      <SelectArrayInput
        source={source}
        alwaysOn={alwaysOn}
        choices={arrayOfChoices}
        label={label}
        className={classes.container}
      />
    </>
  );
};
