import React, { FC } from 'react';
import { Filter, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { constProvider } from 'providers';
import { SwitchButton } from 'components/common/switch-button';
import { Slider } from 'components/common/slider';
import { Multiselect } from 'components/common/multiselect';

const useStyles = makeStyles({
  filter: {
    marginBottom: '80px',
  },
});

export const PathFilter: FC = (props: any) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Filter {...props} className={classes.filter}>
      <SwitchButton
        source="withCase"
        alwaysOn={true}
        label={translate('gTest.project.pathsTab.pathsFilter.withCase')}
      />
      <Slider
        source="scoreThreshold"
        alwaysOn={true}
        label={translate('gTest.project.pathsTab.pathsFilter.scoreThreshold')}
        min={0}
        max={1}
        step={0.1}
      />
      <Multiselect
        source="elementTypes"
        label={translate('gTest.project.pathsTab.pathsFilter.elementTypes')}
        alwaysOn={true}
        choices={constProvider.ELEMENT_TYPES}
      />
    </Filter>
  );
};
