import React, { FC } from 'react';
import { Create, SimpleForm, TextInput, NumberInput, useTranslate } from 'react-admin';
import { validateUrl, validateMaxPathLength, projectTitleValidator } from 'services/validators';

type ProjectCreateProps = ReactAdminCreateEditProps & {};

export const ProjectCreate: FC<ProjectCreateProps> = props => {
  const translate = useTranslate();

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput validate={projectTitleValidator} source="name" />
        <TextInput
          source={'crawler.url'}
          label={translate('gTest.project.form.urlLabel')}
          validate={validateUrl(translate('gTest.project.form.validateUrlErrorMessage'))}
        />
        <NumberInput
          source={'crawler.maxPathLength'}
          label={translate('gTest.project.form.numberLabel')}
          validate={validateMaxPathLength}
        />
      </SimpleForm>
    </Create>
  );
};
