import React, { FC } from 'react';
import { List, Datagrid, TextField, FunctionField, useTranslate, EditButton } from 'react-admin';
import { ProjectEntity } from 'models';
import { ProjectsFilter } from './projects-filter';
import { ProjectStatusChip } from 'components/common/project-status-chip';
import { DateTimeField } from 'components/common/date-time-field';

type ProjectsListProps = {
  [k: string]: ReactAdminListItemProps;
};
const defaultSort = { field: 'createdAt', order: 'DESC' };

export const ProjectsList: FC<ProjectsListProps> = props => {
  const translate = useTranslate();

  const renderName = (inputValue: ProjectEntity) => {
    return <span data-path-id={inputValue.id}>{inputValue.name}</span>;
  };

  const renderStatus = (inputValue: ProjectEntity) => {
    return <ProjectStatusChip status={inputValue.status} />;
  };

  return (
    <List
      {...props}
      sort={defaultSort}
      filters={<ProjectsFilter />}
      title={translate('gTest.projectsList.title')}
    >
      <Datagrid rowClick="edit">
        <TextField
          source="crawler.url"
          sortable={true}
          label={translate('gTest.projectsList.urlLabel')}
        />
        <FunctionField
          label={translate('gTest.projectsList.titleLabel')}
          source="name"
          sortable={true}
          render={renderName}
        />
        <TextField
          label={translate('gTest.projectsList.authorLabel')}
          source="createdBy"
          sortable={true}
        />
        <FunctionField
          label={translate('gTest.projectsList.statusLabel')}
          source="status"
          sortable={true}
          render={renderStatus}
        />
        <TextField
          label={translate('gTest.projectsList.pathsLabel')}
          source="paths"
          sortable={true}
        />
        <TextField
          label={translate('gTest.projectsList.casesLabel')}
          source="cases"
          sortable={true}
        />
        <DateTimeField
          label={translate('gTest.projectsList.createdAtLabel')}
          source="createdAt"
          sortable={true}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
