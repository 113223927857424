import React, { forwardRef, FC } from 'react';
import { MenuItemLink, useTranslate } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import { routerPaths } from 'router/customRoutes';

export const ConfigurationMenu: FC = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to={routerPaths.CONFIGURATION}
      primaryText={translate('app.layout.customAppBar.customUserMenu.configurationMenu.label')}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
    />
  );
});
