import { PathType } from './paths-list.models';
import { numberToPercentage } from 'utils';
import { get } from 'lodash';

export const renderPathField = (data: PathType) => {
  const pathSteps = data.pathSteps;
  return pathSteps ? pathSteps[pathSteps.length - 1].stepGoal : '';
};

export const renderCaseIdField = (data: PathType) => {
  const id = get(data.case, 'caseId', '');
  return id;
};

export const renderScoreField = (data: PathType) => {
  const score = data.score ? numberToPercentage(data.score) : '-';
  return score;
};
