import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  textField: {
    maxWidth: '40px',
    display: 'block',
    textAlign: 'right',
  },
  idField: {
    minWidth: '200px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    float: 'right',
    alignSelf: 'flex-end',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
