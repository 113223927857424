import React, { FC } from 'react';
import {
  Button,
  useCreate,
  useRefresh,
  useNotify,
  useTranslate,
  useUnselectAll,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { constProvider } from 'providers';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { CasesButtonProps, CasesButtonType, MarkType } from './cases-button.models';

const useStyles = makeStyles({
  btn: {
    marginLeft: '20px',
  },
});

let markType: MarkType;
let icon: any;
let notifySuccess: string;
let notifyFailure: string;
let label: string;

export const CasesButton: FC<CasesButtonProps> = ({ selectedIds, type, selectionResource }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const unselectAll = useUnselectAll();

  if (type === CasesButtonType.ADD) {
    markType = MarkType.CASE;
    icon = <AddCircleIcon />;
    notifySuccess = 'common.casesButton.onSuccessAdd';
    notifyFailure = 'common.casesButton.onFailureAdd';
    label = 'common.casesButton.addToCases';
  } else if (type === CasesButtonType.REMOVE) {
    markType = MarkType.PATH;
    icon = <RemoveCircleIcon />;
    notifySuccess = 'common.casesButton.onSuccessRemove';
    notifyFailure = 'common.casesButton.onFailureRemove';
    label = 'common.casesButton.removeFromCases';
  }

  const [onClick, { loading }] = useCreate(
    constProvider.RESOURCES.PROJECT_MARK_PATHS,
    { markType, pathIds: selectedIds },
    {
      onSuccess: () => {
        notify(notifySuccess);
        unselectAll(selectionResource);
        refresh();
      },
      onFailure: (error: Error) => {
        notify(notifyFailure, 'warning');
      },
    },
  );

  return (
    <Button label={translate(label)} disabled={loading} onClick={onClick} className={classes.btn}>
      {icon}
    </Button>
  );
};
