import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Typography, CardContent, Button } from '@material-ui/core';
import { useTranslate, Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { changeTheme } from './actions';
import * as Types from 'Types';

const useStyles = makeStyles({
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const Configuration: FC = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const theme = useSelector((state: Types.RootState) => state.theme);
  const dispatch = useDispatch();

  const onClick = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    dispatch(changeTheme(newTheme));
  };
  return (
    <Card>
      <Title title={translate('app.layout.customAppBar.customUserMenu.configurationMenu.label')} />
      <CardContent className={classes.cardContent}>
        <Typography variant="h5" component="h2" className={classes.label}>
          {translate('app.layout.customAppBar.customUserMenu.configurationMenu.title')}
        </Typography>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === 'light' ? 'primary' : 'default'}
          onClick={onClick}
        >
          {translate('app.layout.customAppBar.customUserMenu.configurationMenu.light')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === 'dark' ? 'primary' : 'default'}
          onClick={onClick}
        >
          {translate('app.layout.customAppBar.customUserMenu.configurationMenu.dark')}
        </Button>
      </CardContent>
    </Card>
  );
};
