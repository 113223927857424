import React, { FC, useCallback, ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Placeholder } from 'components/common/placeholder';
import { ProjectStatus, ProjectEntity } from 'models';
import { CasesList } from './cases-list';
import { cond, matches, stubTrue } from 'lodash';

type CasesTabProps = ReactAdminTabPanelItemProps & {
  project: ProjectEntity;
};

export const CasesTab: FC<CasesTabProps> = ({ project, ...props }) => {
  const translate = useTranslate();

  const dataToDisplay = useCallback(
    (projectStatus?: ProjectStatus) => {
      const renderPlaceholder = (message: string) => {
        return <Placeholder message={message} />;
      };

      const renderCasesList = () => {
        return <CasesList {...props} />;
      };

      return cond<ProjectStatus | undefined, ReactElement>([
        [
          matches(ProjectStatus.DRAFT),
          () => renderPlaceholder(translate('gTest.project.casesTab.notSentStatus')),
        ],
        [
          matches(ProjectStatus.PRE_SCANNING),
          () => renderPlaceholder(translate('gTest.project.casesTab.preScanStatus')),
        ],
        [
          matches(ProjectStatus.PENDING),
          () => renderPlaceholder(translate('gTest.project.casesTab.pendingStatus')),
        ],
        [
          matches(ProjectStatus.CRAWLING),
          () => renderPlaceholder(translate('gTest.project.casesTab.crawlingStatus')),
        ],
        [matches(ProjectStatus.REVIEW), () => renderCasesList()],
        [matches(ProjectStatus.COMPLETED), () => renderCasesList()],
        [stubTrue, () => renderPlaceholder(translate('gTest.project.casesTab.defaultStatus'))],
      ])(projectStatus);
    },
    [translate, props],
  );

  return <div>{dataToDisplay(project ? project.status : undefined)}</div>;
};
