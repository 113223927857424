import React, { HTMLAttributes, ReactNode, MouseEvent } from 'react';
import { isNil } from 'lodash';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  PopperPlacementType,
  MenuItem,
  MenuList,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

type Option = {
  label: string;
  handleClick: () => void;
};

type SplitButtonProps = {
  options: Option[];
  popperPlacement: PopperPlacementType;
  icon?: ReactNode;
  disabled?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

const useStyles = makeStyles({
  popper: {
    zIndex: 1,
  },
});

export const SplitButton = ({
  options,
  popperPlacement,
  icon,
  disabled,
  className,
}: SplitButtonProps) => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const classes = useStyles();

  const handleClick = () => {
    const selectedOption = options[selectedIndex];

    selectedOption.handleClick();
  };

  const onMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: MouseEvent<Document>) => {
    // @ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        className={className}
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
        disabled={disabled}
      >
        <Button onClick={handleClick}>
          {options[selectedIndex].label}
          {!isNil(icon) && icon}
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition={true}
        disablePortal={true}
        placement={popperPlacement}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      selected={index === selectedIndex}
                      onClick={onMenuItemClick.bind(null, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
