import { required, minValue, maxValue, number, minLength, maxLength } from 'react-admin';
import { isValidUrl } from './index';

export const validateUrl = (message: string) => [required(), minValue(1), isValidUrl(message)];

export const validateMaxPathLength = [required(), minValue(1), maxValue(10), number()];

export const projectTitleValidator = [
  required(),
  minLength(3, 'Should be at least 3 characters'),
  maxLength(4096, 'Should be at most 4096 characters'),
];
