import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'react-admin';
import * as Types from 'Types';
import { darkTheme, lightTheme } from './themes';
import { CustomMenu } from './custom-menu';
import { CustomAppBar } from './custom-app-bar/custom-app-bar';

export const CustomLayout: FC = props => {
  const theme = useSelector((state: Types.RootState) =>
    state.theme === 'dark' ? darkTheme : lightTheme,
  );

  return <Layout {...props} menu={CustomMenu} appBar={CustomAppBar} theme={theme} />;
};
