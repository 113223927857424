import React, { FC } from 'react';
import { useTranslate } from 'react-admin';

import { PostTitleProps } from './page-title.models';

export const PostTitle: FC<PostTitleProps> = props => {
  const translate = useTranslate();
  return (
    <span>
      {translate('gTest.project.postTitle')}:{props.record ? `"${props.record.name}"` : ''}
    </span>
  );
};
