import React, { FC } from 'react';
import { Typography, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';

type SwitchButtonProps = ReactAdminFilterProps & {
  label: string;
  source: string;
};

const useStyles = makeStyles({
  switch: {
    paddingLeft: '20px',
    marginBottom: '-4px',
  },
});

export const SwitchButton: FC<SwitchButtonProps> = ({ label, source }) => {
  const classes = useStyles();

  return (
    <Field name={source}>
      {(props: ReactAdminFilterProps) => (
        <>
          <Typography color="textPrimary">{label}</Typography>
          <Switch
            className={classes.switch}
            color="primary"
            name={props.input.name}
            onChange={props.input.onChange}
            checked={props.input.value}
          />
        </>
      )}
    </Field>
  );
};
