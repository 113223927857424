import React, { FC } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useTranslate, TextInput } from 'react-admin';
import { CardActions, Button } from '@material-ui/core';
import { requiredValidator } from 'services/validators';
import { AuthLoginParams } from '../login.models';
import { useStyles } from '../login-form.styles';

type DefaultLoginFormProps = {
  login: (auth: AuthLoginParams) => void;
};

export const DefaultLoginForm: FC<DefaultLoginFormProps> = ({ login }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const submit = (values: any) => {
    login({
      username: values.username,
      password: values.password,
    });
  };

  const renderForm = ({ handleSubmit }: FormRenderProps<any>) => {
    return (
      <form onSubmit={handleSubmit}>
        <div className={classes.form}>
          <div>
            <TextInput
              source="username"
              label={translate('ra.auth.username')}
              validate={requiredValidator}
              fullWidth={true}
            />
          </div>
          <div>
            <TextInput
              source="password"
              label={translate('ra.auth.password')}
              type="password"
              validate={requiredValidator}
              fullWidth={true}
            />
          </div>
        </div>
        <CardActions>
          <Button variant="contained" type="submit" color="primary" className={classes.button}>
            {translate('ra.auth.sign_in')}
          </Button>
        </CardActions>
      </form>
    );
  };

  return <Form onSubmit={submit} render={renderForm} />;
};
