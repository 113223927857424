import React, { FC } from 'react';
import {
  List,
  useTranslate,
  TextField,
  FunctionField,
  Datagrid,
  useCreate,
  useRefresh,
  useNotify,
} from 'react-admin';
import { constProvider } from 'providers';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as Types from 'Types';
import { isEmpty } from 'lodash';
import { RowExpanded } from 'components/common/row-expanded';
import { getProjectPathsFiltersSelector } from 'features/admin/selectors';
import { renderPathField, renderCaseIdField, renderScoreField } from './paths-list.utils';
import { PathFilter } from './paths-filter';
import { PathsBulkActionButtons } from './paths-bulk-action-buttons';
import { useStyles } from './paths-list.styles';

const filterDefaultValues = {
  scoreThreshold: [0.7, 1],
  withCase: false,
  elementTypes: constProvider.ELEMENT_TYPES_DEFAULT_SELECTED,
};

type PathsListProps = ReactAdminListProps & {
  statusDisabled: boolean;
};

export const PathsList: FC<PathsListProps> = ({ statusDisabled, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const filtersValues = useSelector((state: Types.RootState) =>
    getProjectPathsFiltersSelector(state),
  );
  const filterValue = isEmpty(filtersValues) ? filterDefaultValues : filtersValues;

  const [onClick] = useCreate(
    constProvider.RESOURCES.PROJECT_MARK_ALL_PATHS,
    { filter: filterValue },
    {
      onSuccess: () => {
        notify('gTest.project.pathsTab.pathsList.successMessage');
        refresh();
      },
      onFailure: (error: Error) => {
        notify('gTest.project.pathsTab.pathsList.errorMessage', 'warning');
      },
    },
  );

  return (
    <div className={classes.container}>
      <Button variant="outlined" className={classes.button} onClick={onClick}>
        {translate('gTest.project.pathsTab.pathsList.allAsCasesButton')}
      </Button>
      <List
        {...props}
        resource={constProvider.RESOURCES.PROJECT_PATHS}
        hasList={false}
        hasEdit={false}
        hasShow={false}
        hasCreate={false}
        title={translate('gTest.project.pathsTab.pathsList.title')}
        filters={<PathFilter />}
        filterDefaultValues={filterDefaultValues}
        bulkActionButtons={
          statusDisabled ? (
            false
          ) : (
            <PathsBulkActionButtons selectionResource={constProvider.RESOURCES.PROJECT_PATHS} />
          )
        }
      >
        <Datagrid rowClick="expand" expand={<RowExpanded />}>
          <TextField
            source="id"
            label={translate('gTest.project.pathsTab.pathsList.id')}
            className={classes.idField}
          />
          <FunctionField
            label={translate('gTest.project.pathsTab.pathsList.stepGoal')}
            render={renderPathField}
          />
          <TextField
            source="steps"
            sortable={true}
            label={translate('gTest.project.pathsTab.pathsList.steps')}
            className={classes.textField}
          />
          <FunctionField
            render={renderScoreField}
            source="score"
            sortable={true}
            label={translate('gTest.project.pathsTab.pathsList.score')}
            className={classes.textField}
          />
          <FunctionField
            render={renderCaseIdField}
            label={translate('gTest.project.pathsTab.pathsList.case')}
          />
        </Datagrid>
      </List>
    </div>
  );
};
