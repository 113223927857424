import { Location } from 'history';

export const getPathName = (location: Location) => {
  let pathname = location.pathname;
  if (pathname && pathname[0] === '/') {
    pathname = pathname.substring(1);
  }
  return pathname;
};

export const getEntityIdFromUrl = (location: Location) => {
  const pathname = location.pathname;
  return pathname.split('/').pop();
};
