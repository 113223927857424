import moment from 'moment';
import { constProvider } from '../providers/const';

export const dateTimeFormat = (v: string) => {
  const formattedDate = moment(v).format(constProvider.DATE_TIME_FORMAT);

  return `${formattedDate} UTC`;
};

export const dateTimeParse = (v: string) => {
  const formattedDate = v.replace('UTC', '').trim();

  return moment(formattedDate).toISOString();
};
