import { timer, from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GetOneResult } from 'react-admin';
import { dataProvider } from 'providers/data';

const PROGRESS_CHECK_DELAY = 1000;

export const getCrawlingProgress = (resource: string): Observable<typeof GetOneResult> =>
  timer(0, PROGRESS_CHECK_DELAY).pipe(
    switchMap(() => from(dataProvider.getOne(resource, { id: 'crawling-progress' }))),
  );
