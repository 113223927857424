import { MutableRefObject, useEffect } from 'react';
import { Subject, of, Observable } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { isFunction } from 'lodash';

export function useDestroySubscriptionsRefEffect<T>(
  destroySubscriptionsRef: MutableRefObject<Subject<any>>,
  getInputObservable?: () => Observable<T>,
) {
  useEffect(() => {
    const current = destroySubscriptionsRef.current;

    if (isFunction(getInputObservable)) {
      getInputObservable()
        .pipe(
          takeUntil(current),
          catchError(() => {
            return of(undefined);
          }),
        )
        .subscribe();
    }

    return () => {
      current.next();
      current.complete();
    };
  }, [destroySubscriptionsRef, getInputObservable]);
}
