import Amplify from 'aws-amplify';
import { split } from 'lodash';
import { constProvider } from '../const';

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: constProvider.AUTH.AWS_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: constProvider.AUTH.AWS_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: constProvider.AUTH.AWS_COGNITO_WEB_CLIENT_ID,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: constProvider.AUTH.AWS_COGNITO_AUTHENTICATION_FLOW_TYPE,

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: constProvider.AUTH.AWS_COGNITO_OAUTH_DOMAIN,
      scope: split(constProvider.AUTH.AWS_COGNITO_OAUTH_SCOPE, ','),
      redirectSignIn: constProvider.AUTH.AWS_COGNITO_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: constProvider.AUTH.AWS_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
      responseType: constProvider.AUTH.AWS_COGNITO_OAUTH_RESPONSE_TYPE, // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});
