import { get } from 'lodash';
import * as Types from 'Types';
import { constProvider } from 'providers/const';

const projectBasePath = constProvider.RESOURCES.PROJECT;
const projectPathToPaths = constProvider.RESOURCES.PROJECT_PATHS;

export const getProjectFromReactAdminSelector = (state: Types.RootState, projectId: string) => {
  return get(state.admin.resources[projectBasePath].data, projectId);
};

export const getProjectPathsFiltersSelector = (state: Types.RootState) => {
  return state.admin.resources[projectPathToPaths].list.params.filter;
};
