import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  button: {
    marginTop: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '150px',
    height: '105px',
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 0px 10px 2px rgba(173,173,173,1)',
    },
  },
  buttonImg: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  modalImg: {
    maxWidth: '75%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  error: {
    color: 'red',
  },
});
