import { CaseType } from 'models';
import { numberToPercentage } from 'utils';

export const renderPathsField = (data: CaseType) => {
  const path = data.path.value ? data.path.value : '';
  return path;
};

export const renderScoreField = (data: CaseType) => {
  const score = data.score ? numberToPercentage(data.score) : '-';
  return score;
};
