import React, { FC } from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import { CustomCloseButton } from '../custom-close-button';

type FeedbackToolbarProps = ReactAdminToolbarProps & {
  onSave: () => void;
  onClose: () => void;
};

export const FeedbackToolbar: FC<FeedbackToolbarProps> = ({
  onSave,
  onClose,
  disabled,
  ...props
}) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" onSave={onSave} disabled={disabled} />
      <CustomCloseButton onClick={onClose} />
    </Toolbar>
  );
};
