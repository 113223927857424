import React, { FC, useCallback, useMemo, useState } from 'react';
import { Toolbar, useDataProvider, useNotify, useTranslate, useRefresh } from 'react-admin';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StopIcon from '@material-ui/icons/Stop';
import { matches, constant, stubTrue, cond } from 'lodash';
import * as Types from 'Types';
import { ProjectStatus } from 'models';
import { getPathName } from 'utils';
import { constProvider } from 'providers/const';
import { SplitButton } from 'components/common/split-button';
import { CrawlerToolbarLoader } from './crawler-toolbar-loader';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    padding: 0,
    justifyContent: 'space-between',
  },
  button: {
    marginRight: '10px',
  },
});

type CrawlerTabToolbarProps = ReactAdminToolbarProps & {
  statusDisabled: boolean;
  isProjectCrawling: boolean;
  projectStatus: ProjectStatus;
};

enum AnalyzerType {
  'STATIC' = 'static',
  'SELENIUM' = 'selenium',
}

export const CrawlerTabToolbar: FC<CrawlerTabToolbarProps> = ({
  isProjectCrawling,
  statusDisabled,
  projectStatus,
}) => {
  const router = useSelector((state: Types.RootState) => state.router);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles();
  const refresh = useRefresh();

  const [isCrawlingStarted, setIsCrawlingStarted] = useState<boolean>(isProjectCrawling);

  const handleStartPreScan = useCallback(() => {
    dataProvider
      .create(
        `${getPathName(router.location)}/${constProvider.RESOURCES.PROJECT_START_PRE_SCAN}`,
        {},
      )
      .then(() => {
        refresh();
        notify('gTest.project.crawlerTab.startNotifySuccess');
      })
      .catch((error: Error) => {
        notify('gTest.project.crawlerTab.startNotifyFailure', 'warning', {
          errorMessage: error.message,
        });
      });
  }, [dataProvider, notify, router.location, refresh]);

  const handleStartCrawler = useCallback(
    (analyzerType: AnalyzerType) => {
      dataProvider
        .create(
          `${getPathName(router.location)}/${constProvider.RESOURCES.PROJECT_START_CRAWLER}`,
          { data: { analyzer: analyzerType } },
        )
        .then(() => {
          setIsCrawlingStarted(true);
          notify('gTest.project.crawlerTab.startNotifySuccess');
        })
        .catch((error: Error) => {
          notify('gTest.project.crawlerTab.startNotifyFailure', 'warning', {
            errorMessage: error.message,
          });
        });
    },
    [dataProvider, notify, router.location],
  );

  const handleStopCrawler = useCallback(() => {
    dataProvider
      .create(`${getPathName(router.location)}/${constProvider.RESOURCES.PROJECT_STOP_CRAWLER}`, {})
      .then(() => {
        setIsCrawlingStarted(false);
        notify('gTest.project.crawlerTab.stopNotifySuccess');
      })
      .catch((error: Error) => {
        notify('gTest.project.crawlerTab.stopNotifyFailure', 'warning', {
          errorMessage: error.message,
        });
      });
  }, [dataProvider, notify, router.location]);

  const options = useMemo(
    () => [
      {
        label: translate('gTest.project.crawlerTab.startCrawlerSimpleBtnLabel'),
        handleClick: handleStartCrawler.bind(null, AnalyzerType.STATIC),
      },
      {
        label: translate('gTest.project.crawlerTab.startCrawlerExtendedBtnLabel'),
        handleClick: handleStartCrawler.bind(null, AnalyzerType.SELENIUM),
      },
    ],
    [translate, handleStartCrawler],
  );

  const buttonsToShow = useCallback(() => {
    const buttonPreScan = () => (
      <Button
        variant="contained"
        color="primary"
        disabled={statusDisabled}
        onClick={handleStartPreScan}
        className={classes.button}
      >
        {translate('gTest.project.crawlerTab.startPreScanBtnLabel')}
      </Button>
    );

    const buttonsCrawling = () => (
      <>
        <SplitButton
          className={classes.button}
          options={options}
          popperPlacement="top"
          disabled={isCrawlingStarted || statusDisabled}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={!isCrawlingStarted}
          onClick={handleStopCrawler}
          className={classes.button}
        >
          {translate('gTest.project.crawlerTab.stopCrawlerBtnLabel')}
          <StopIcon />
        </Button>
      </>
    );

    const noButtons = () => <span>{null}</span>;

    const displayBasedOnStatus = cond([
      [matches(ProjectStatus.DRAFT), constant(buttonPreScan)],
      [matches(ProjectStatus.PRE_SCANNING), constant(buttonPreScan)],
      [matches(ProjectStatus.PENDING), constant(buttonsCrawling)],
      [matches(ProjectStatus.CRAWLING), constant(buttonsCrawling)],
      [matches(ProjectStatus.REVIEW), constant(noButtons)],
      [matches(ProjectStatus.COMPLETED), constant(noButtons)],
      [stubTrue, constant(buttonPreScan)],
    ])(projectStatus);

    return displayBasedOnStatus();
  }, [
    projectStatus,
    classes,
    handleStartPreScan,
    handleStopCrawler,
    isCrawlingStarted,
    options,
    statusDisabled,
    translate,
  ]);

  return (
    <Toolbar className={classes.root}>
      {/* Have to add fragment because Toolbar clones props to children which causes wrong attr on div */}
      <>
        <div>{buttonsToShow()}</div>
      </>
      {isCrawlingStarted && <CrawlerToolbarLoader />}
    </Toolbar>
  );
};
