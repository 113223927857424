import React, { FC } from 'react';
import { Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    margin: '10px 0',
  },
  chip: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
});

type LinkProps = {
  linkIcon: any;
  href: string;
  title: string;
};

export const Link: FC<LinkProps> = ({ linkIcon, href, title }) => {
  const classes = useStyles();

  return (
    <a href={href} className={classes.link}>
      <Chip
        variant="outlined"
        color="secondary"
        icon={linkIcon}
        label={<Typography className={classes.chip}>{title}</Typography>}
      />
    </a>
  );
};
