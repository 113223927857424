import React, { FC } from 'react';
import { useNotify, useUpdate, useTranslate } from 'react-admin';
import { isFunction, isNil } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import IconButton from '@material-ui/core/IconButton';
import { constProvider } from 'providers/const';
import { AssessedStatus, FeedbackType } from 'models';
import { getAssessedStatus } from 'utils';

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  button: {
    padding: '4px',
  },
});

type ThumbButtonsType = {
  feedback?: FeedbackType;
  assessedStatus?: AssessedStatus;
  disabled: boolean;
  onThumbUpClick?: () => void;
  onThumbDownClick?: () => void;
  onActionSuccessful?: (updatedFeedback: FeedbackType) => void;
};

export const ThumbButtons: FC<ThumbButtonsType> = ({
  feedback,
  assessedStatus,
  disabled,
  onThumbUpClick,
  onThumbDownClick,
  onActionSuccessful,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();

  const onFeedbackSuccess = ({ data }: { data: FeedbackType }) => {
    notify('common.feedbackField.feedbackUpdate', 'info', {});

    if (isFunction(onActionSuccessful)) {
      onActionSuccessful(data);
    }
  };

  const onFeedbackFailure = (error: Error) => notify(`Error: ${error.message}`, 'warning');

  const getUpdateData = (newStatus: AssessedStatus) => ({
    assessed: !isNil(feedback) ? getAssessedStatus(feedback.assessed, newStatus) : null,
    comment: !isNil(feedback) ? feedback.comment : null,
  });

  const [updateFeedbackThumbUp] = useUpdate(
    constProvider.RESOURCES.PROJECT_FEEDBACK,
    feedback?.id,
    getUpdateData(AssessedStatus.OK),
    feedback,
    {
      onSuccess: onFeedbackSuccess,
      onFailure: onFeedbackFailure,
    },
  );

  const [updateFeedbackThumbDown] = useUpdate(
    constProvider.RESOURCES.PROJECT_FEEDBACK,
    feedback?.id,
    getUpdateData(AssessedStatus.NOTOK),
    feedback,
    {
      onSuccess: onFeedbackSuccess,
      onFailure: onFeedbackFailure,
    },
  );

  const handleThumbUpClick = () => {
    if (isFunction(onThumbUpClick)) {
      onThumbUpClick();
    } else {
      updateFeedbackThumbUp();
    }
  };

  const handleThumbDownClick = () => {
    if (isFunction(onThumbDownClick)) {
      onThumbDownClick();
    } else {
      updateFeedbackThumbDown();
    }
  };

  const assessed = !isNil(feedback) ? feedback.assessed : assessedStatus;
  const thumbUpColor = assessed === AssessedStatus.OK ? 'secondary' : 'disabled';
  const thumbDownColor = assessed === AssessedStatus.NOTOK ? 'secondary' : 'disabled';

  return (
    <div className={classes.container}>
      <IconButton
        aria-label={translate('common.thumbButtons.thumbUpLabel')}
        onClick={handleThumbUpClick}
        className={classes.button}
        disabled={disabled}
      >
        <ThumbUpAltIcon color={thumbUpColor} />
      </IconButton>
      <IconButton
        aria-label={translate('common.thumbButtons.thumbDownLabel')}
        onClick={handleThumbDownClick}
        className={classes.button}
        disabled={disabled}
      >
        <ThumbDownAltIcon color={thumbDownColor} />
      </IconButton>
    </div>
  );
};
