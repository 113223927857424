import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';

type RowExpandedProps = {
  data: string | number | JSX.Element;
};

const useStyles = makeStyles({
  container: {
    backgroundColor: 'rgba(245, 245, 245, 0.5)',
    maxWidth: '400px',
    overflow: 'overlay',
  },
});

export const Dropdown: FC<RowExpandedProps> = ({ data }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <ExpansionPanel className={classes.container}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        {translate('common.rowExpanded.dropdown.header')}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{data}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
