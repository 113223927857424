import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CasesButton } from 'components/common/cases-button';
import { CasesButtonType } from 'components/common/cases-button/cases-button.models';

const useStyles = makeStyles({
  container: {
    marginBottom: '20px',
  },
});

type PathsBulkActionButtons = {
  selectionResource: string;
};

export const PathsBulkActionButtons: FC<PathsBulkActionButtons> = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CasesButton
        {...props}
        type={CasesButtonType.ADD}
        selectionResource={props.selectionResource}
      />
      <CasesButton
        {...props}
        type={CasesButtonType.REMOVE}
        selectionResource={props.selectionResource}
      />
    </div>
  );
};
